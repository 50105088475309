import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Flex } from "@styled-system/jsx";
import { Button, Heading, Skeleton, SuggestionCard } from "doowii-ui";
import { useNavigate } from "react-router-dom";

import { Doowii } from "../../search/Doowii";
import { QuestionTypeEnum } from "../../search/Doowii.i";

interface Props {
  doowii: Doowii;
  homePage: boolean;
}
const SuggestionsCardsContainer: React.FC<Props> = ({ doowii, homePage }: Props) => {
  const { _ } = useLingui();
  const { authUser } = useAuth();
  const navigate = useNavigate();

  const { dynamicCache, fetchingSuggestions, refetchSuggestions, currentThread } = useChatData();

  const onHandleSuggestionClick = async (suggestion: string) => {
    if (homePage) {
      navigate(`/${PATHS.USERS}/${authUser?.uid}/${PATHS.THREADS}/${currentThread}`);
    }
    await doowii.chat({
      query: suggestion,
      index: 0,
      questionType: QuestionTypeEnum.SUGGESTION,
    });
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        gap: "md",
        w: "full",
      }}
    >
      <Flex css={{ alignItems: "center", gap: "sm" }}>
        <Heading level={3}>
          <Trans>Don’t know where to start? Try these or refresh for more suggestions</Trans>
        </Heading>
        <Button
          aria-label={_(msg`"Refresh suggestions"`)}
          iconOnly={faArrowsRotate}
          onClick={refetchSuggestions}
          size="medium"
          variant="dropdown"
        />
      </Flex>
      {fetchingSuggestions ? (
        <>
          <Skeleton height="92px" maxWidth="720px" w="full" />
          <Skeleton height="92px" maxWidth="720px" w="full" />
          <Skeleton height="92px" maxWidth="720px" w="full" />
          {!homePage ? <Skeleton height="92px" maxWidth="720px" w="full" /> : null}
        </>
      ) : (
        dynamicCache.slice(!homePage ? 0 : 1, 4).map((suggestion, index) => (
          <SuggestionCard
            key={index}
            label={_(msg`Start new chat with suggestion: ${suggestion}`)}
            onClick={() => onHandleSuggestionClick(suggestion)}
          >
            {suggestion}
          </SuggestionCard>
        ))
      )}
    </Flex>
  );
};

export { SuggestionsCardsContainer };
