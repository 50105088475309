import { UserDocument } from "@doowii-types/user";
import { withSentry } from "@utils/wrapper";
import {
  createUserWithEmailAndPassword,
  User as FirebaseUser,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { fetchAndProcessUserDocument, isInvitedUser } from "./user";

const auth = getAuth();

/**
 * Signs out the current user.
 */
const signOutUser = withSentry(async () => {
  await signOut(auth);
});

/**
 * Signs in a user with email and password.
 * @param email - The user's email.
 * @param password - The user's password.
 * @returns A Promise that resolves with the user credentials.
 */
const signInWithFirebase = withSentry((email: string, password: string) =>
  signInWithEmailAndPassword(auth, email, password)
);

/**
 * Signs in a user with Google.
 * @returns A Promise that resolves with the user credentials.
 */
const signInWithGoogle = withSentry( async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;

  if(!user || !user.email) {
    throw new Error("Google sign in failed");
  }
  const isInvited = await isInvitedUser(user.email);
  if(!isInvited) {
    console.error("User not invited: ", user.email);
    await user.delete();
    return false;
  }
  return true;
});

/**
 * Signs up a new user with email and password.
 * @param email - The user's email.
 * @param password - The user's password.
 * @returns A Promise that resolves with the user credentials.
 */
const signUpWithFirebase = withSentry(async (email: string, password: string) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  await sendEmailVerification(userCredential.user);
  return userCredential;
});

/**
 * Sends a password reset email to the specified email address.
 * @param email - The email address to send the password reset email to.
 * @returns A Promise that resolves when the email has been sent.
 */
const sendPwdResetEmailWithFirebase = withSentry((email: string) =>
  sendPasswordResetEmail(auth, email)
);

interface AuthListenerResponse {
  authUser: FirebaseUser | null;
  userDocument: UserDocument | null;
  organization: string | null;
}

/**
 * Initializes the authentication listener.
 * @param callback - The callback function to be called with the authentication response.
 */
const initializeAuthListener = (callback: (response: AuthListenerResponse) => void) => {
  onAuthStateChanged(auth, async (firebaseUser) => {
    const response: AuthListenerResponse = {
      authUser: firebaseUser,
      userDocument: null,
      organization: null,
    };
    if (firebaseUser) {
      const result = await fetchAndProcessUserDocument(firebaseUser.uid);
      if (result) {
        response.userDocument = result.userDocument;
        response.organization = result.organization;
      }
    }
    callback(response);
  });
};

export {
  auth,
  initializeAuthListener,
  sendPwdResetEmailWithFirebase,
  signInWithFirebase,
  signInWithGoogle,
  signOutUser,
  signUpWithFirebase
};

