import { UserAvatar } from "@components/UserAvatar";
import { HIGHER_ED } from "@constants/constants";
import { MANAGE_USERS } from "@constants/features";
import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { faChevronDown, faPowerOff } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "doowii-ui";
import { Link, useSubmit } from "react-router-dom";

import { env } from "../../env";
import { useAuth } from "../../hooks/useAuth";

const UserDropdown = () => {
  const { _ } = useLingui();
  const submit = useSubmit();
  const { authUser, featureFlags } = useAuth();
  const { loading, streamLoading } = useChatData();
  const disableNavigation = loading || streamLoading;

  const userDisplayName = authUser?.displayName ?? authUser?.email ?? _(msg`Unknown User`);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disableNavigation}>
        <Button
          aria-label={_(msg`Open account menu options`)}
          data-testid="user-dropdown-button"
          style={{ padding: "2" }}
          variant="dropdown"
        >
          <Flex css={{ alignItems: "center", gap: "10px" }}>
            <UserAvatar />
            {userDisplayName}
            <FontAwesomeIcon icon={faChevronDown} />
          </Flex>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className={css({ w: "200px" })}>
        <Link to={PATHS.SETTINGS}>
          <DropdownMenuItem>
            <Trans>Settings</Trans>
          </DropdownMenuItem>
        </Link>
        {env.REACT_APP_ENV !== HIGHER_ED || featureFlags?.includes(MANAGE_USERS) ? (
          <Link to={PATHS.USER_MANAGEMENT}>
            <DropdownMenuItem>
              <Trans>Manage users</Trans>
            </DropdownMenuItem>
          </Link>
        ) : null}

        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => submit(null, { method: "post", action: PATHS.SIGN_OUT })}>
          <FontAwesomeIcon icon={faPowerOff} />
          <Trans>Sign out</Trans>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { UserDropdown };
