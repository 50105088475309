import { AuthSliceInterface } from "../stores/authSlice";
import { useBoundStore } from "../stores/store";

const useAuth = (): Partial<AuthSliceInterface> => {
  const authUser = useBoundStore((state) => state.authUser);
  const userDocument = useBoundStore((state) => state.userDocument);
  const isLoading = useBoundStore((state) => state.isLoading);
  const organization = useBoundStore((state) => state.organization);
  const featureFlags = useBoundStore((state) => state.featureFlags);
  const createNewUser = useBoundStore((state) => state.createNewUser);
  const updateUserDocument = useBoundStore((state) => state.updateUserDocument);
  const deleteAuthUser = useBoundStore((state) => state.deleteAuthUser);
  const signOut = useBoundStore((state) => state.signOut);
  const fetchUserDocument = useBoundStore((state) => state.fetchUserDocument);
  const initializeAuthListener = useBoundStore((state) => state.initializeAuthListener);

  return {
    authUser,
    userDocument,
    isLoading,
    organization,
    featureFlags,
    createNewUser,
    updateUserDocument,
    deleteAuthUser,
    signOut,
    fetchUserDocument,
    initializeAuthListener,
  };
};

export { useAuth };
