import { withSentry } from "@utils/wrapper";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { db } from "./connection";
import { getDocumentFromFirestore } from "./utils";
import { post } from "@services/webserver/base";
import { components, operations } from "@doowii-types/WebserverInterfaces.gen";
import { AxiosResponse } from "axios";

type InviteUserRequest =
  components["schemas"]["InviteUserRequest"];

type InviteUserResponse =
  operations["invite_user_api_users_invite_user_post"]["responses"]["200"]["content"]["application/json"];

export const fetchOrganizationById = withSentry((orgId: string) =>
  getDocumentFromFirestore("organizations", orgId)
);

export const incrementUsageMetrics = withSentry(async (organizationId: string) => {
  const now = new Date();
  const monthYear = `${now.getUTCFullYear()}-${String(now.getUTCMonth() + 1).padStart(2, "0")}`;
  const orgDocRef = doc(db, "organizations", organizationId);
  const orgDoc = await getDoc(orgDocRef);
  if (!orgDoc.exists()) {
    console.error("Organization document does not exist.");
    return;
  }

  const orgData = orgDoc.data();
  const currentMonthlyUsage = orgData.monthly_usage || {};
  const newMonthlyCount = (currentMonthlyUsage[monthYear] || 0) + 1;

  const monthlyUsageUpdate = `monthly_usage.${monthYear}`;

  await updateDoc(orgDocRef, {
    chargeable_actions: increment(1),
    [monthlyUsageUpdate]: newMonthlyCount,
  });
});

export const updateLastSignIn = withSentry(async (orgId: string, userId: string) => {
  const userRef = doc(db, "organizations", orgId, "users", userId);
  await updateDoc(userRef, {
    LSO: new Date(Date.now()).toLocaleDateString(),
  });
});

export const fetchOrganizationName = withSentry(async (orgId: string) => {
  const org = await getDocumentFromFirestore("organizations", orgId);
  return org?.name || null;
});

export const createInvitation = withSentry(async (orgId: string, email: string): Promise<AxiosResponse<InviteUserResponse> | undefined> => {
  try {
    const request: InviteUserRequest = {email: email, org_id: orgId, role: "admin"}
    const response = await post("/users/invite_user", request);
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
});

export const createSource = withSentry(
  async (
    handling: string,
    organizationId: string,
    name: string,
    source: string,
    sourceType: string,
    userId: string
  ) => {
    const sourcesRef = collection(db, "organizations", organizationId, "sources");

    if (handling === "demo_sources") {
      const demoQuery = query(
        sourcesRef,
        where("handling", "==", "demo_sources"),
        where("user_id", "==", userId)
      );
      const demoSnapshot = await getDocs(demoQuery);
      if (!demoSnapshot.empty) {
        throw new Error("You already have a demo source!");
      }
    }

    const srcQuery = query(sourcesRef, where("source", "==", source));
    const srcSnapshot = await getDocs(srcQuery);

    if (!srcSnapshot.empty) {
      throw new Error("Source already exists!");
    }

    const now = new Date();
    const docRef = await addDoc(sourcesRef, {
      created_at: now,
      organizationId,
      source,
      sourceType,
      sync_status: "UPLOADED",
      synced_at: now,
      userId,
      handling,
      name,
    });

    return docRef.id;
  }
);

export const getSourceType = withSentry(async (orgId: string) => {
  const sourcesCollectionRef = collection(db, "organizations", orgId, "sources");
  const querySnapshot = await getDocs(sourcesCollectionRef);

  if (querySnapshot.empty) {
    console.warn("No sources found for the organization");
    return ["GOOGLE_SHEETS"];
  }
  return querySnapshot.docs.map((doc) => doc.data().source_type);
});

export const fetchDiagramUrl = withSentry(async (orgId: string) => {
  const docRef = doc(db, "organizations", orgId);
  const defaultUrl = "";

  const docSnap = await getDoc(docRef);
  if (docSnap.exists() && docSnap.data().diagram_url) {
    return docSnap.data().diagram_url;
  }
  return defaultUrl;
});

export const fetchOrganizationId = withSentry(async (userId: string) => {
  const userOrgs = await getDocumentFromFirestore("user_orgs", userId);
  return userOrgs?.organization || null;
});

export const fetchInvitationByOrgId = withSentry(async (orgId) => {
  const docRef = doc(db, "organizations", orgId);
  const docSnapshot = await getDoc(docRef);
  return docSnapshot.exists() ? docSnapshot.data() : null;
});

export const updateValidationStatus = withSentry(async (docId, newStatus) => {
  const docRef = doc(db, "organization_questions", docId);
  await setDoc(docRef, { validation_status: newStatus }, { merge: true });
});
