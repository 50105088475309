import { useChatData } from "@context/chat";
import { Result } from "@doowii-types/chat";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@hooks/useAuth";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import * as Collapsible from "@radix-ui/react-collapsible";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { getRecentMessages } from "@utils/getRecentMessages";
import { FollowUpCard, Separator, Text } from "doowii-ui";
import { useState } from "react";

import { Doowii } from "../../../../search/Doowii";
import { QuestionTypeEnum } from "../../../../search/Doowii.i";

interface ChatFollowupProps {
  result: Result;
}
const ChatFollowup = ({ result }: ChatFollowupProps) => {
  const hasFollowUp = result.follow_up_prompts && result.follow_up_prompts.length > 0;
  const [open, setOpen] = useState(false);
  const { userDocument } = useAuth();

  const {
    answer,
    allResults,
    setAllResults,
    setAnswer,
    setLoading,
    setStreamLoading,
    currentThread,
    threads,
  } = useChatData();
  const { i18n } = useLingui();

  const doowii = new Doowii(
    i18n,
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    userDocument,
    currentThread,
    allResults,
    threads
  );

  const handleClickFollowup = async (followUp: string) => {
    await doowii.chat({
      query: followUp,
      index: allResults.length,
      recentMessages: getRecentMessages({ allResults, answer }),
      questionType: QuestionTypeEnum.REFINEMENT,
    });
  };

  return (
    <Collapsible.Root
      className={css({
        px: "4",
        pb: "2",
        w: "full",
        marginX: "auto",
      })}
      onOpenChange={setOpen}
      open={open}
    >
      {hasFollowUp ? (
        <>
          <Separator colorful={false} />
          <Collapsible.Trigger>
            <Flex
              align="center"
              cursor="pointer"
              direction="row"
              gap="4"
              justify="center"
              p="3"
              textWrap="nowrap"
            >
              <Text className={css({ fontWeight: "medium" })} level={3} variant="constrast">
                {open ? <Trans>Hide follow-ups </Trans> : <Trans>Show follow-ups</Trans>}
              </Text>
              <FontAwesomeIcon
                className={css({
                  w: "6",
                  h: "6",
                })}
                icon={open ? faChevronUp : faChevronDown}
              />
            </Flex>
          </Collapsible.Trigger>
        </>
      ) : null}

      <Collapsible.Content>
        <Flex align="stretch" direction="row" gap="4" pb="4">
          {result.follow_up_prompts
            ? result.follow_up_prompts.map((followUp, index) => (
                <FollowUpCard
                  key={index}
                  onClick={() => {
                    handleClickFollowup(followUp);
                  }}
                >
                  {followUp}
                </FollowUpCard>
              ))
            : null}
        </Flex>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export { ChatFollowup };
