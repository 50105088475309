// src/AlertDialog/AlertDialog.tsx
import { cx, sva } from "@doowii/design-system/css";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import * as React2 from "react";

// src/Button/Button.tsx
import { cva } from "@doowii/design-system/css";
import { Box } from "@doowii/design-system/jsx";
import {
  FontAwesomeIcon as FontAwesomeIcon2
} from "@fortawesome/react-fontawesome";
import React from "react";

// src/Spinner/Spinner.tsx
import { css } from "@doowii/design-system/css";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import {
  FontAwesomeIcon
} from "@fortawesome/react-fontawesome";
import { jsx } from "react/jsx-runtime";
var Spinner = (props) => /* @__PURE__ */ jsx(
  FontAwesomeIcon,
  {
    className: css({ animation: "spin" }),
    icon: faSpinner,
    ...props
  }
);

// src/Button/Button.tsx
import { jsx as jsx2, jsxs } from "react/jsx-runtime";
var buttonRecipe = cva({
  base: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    gap: "sm",
    cursor: "pointer",
    borderWidth: "2px",
    borderStyle: "solid",
    fontWeight: "medium"
  },
  variants: {
    size: {
      xsmall: {
        padding: "sm",
        textStyle: "paragraph_14",
        borderRadius: "md",
        height: "32px"
      },
      small: {
        padding: "md",
        textStyle: "paragraph_16",
        borderRadius: "lg",
        height: "40px"
      },
      medium: {
        py: `md`,
        px: `ld`,
        textStyle: "paragraph_18",
        borderRadius: "xl",
        height: "48px"
      },
      large: {
        py: `md`,
        px: `ld`,
        textStyle: "paragraph_18",
        borderRadius: "xl",
        height: "56px"
      }
    },
    variant: {
      primary: {
        backgroundColor: "background.primary",
        color: "foreground.primary",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "background.hover.primary",
          color: "foreground.hover.primary"
        },
        _active: {
          backgroundColor: "background.active",
          color: "foreground.active"
        }
      },
      secondary: {
        backgroundColor: "background.secondary",
        color: "foreground.secondary",
        borderColor: "border.secondary",
        _hover: {
          backgroundColor: "background.hover.secondary",
          color: "foreground.hover.secondary",
          borderColor: "border.hover.secondary"
        },
        _active: {
          backgroundColor: "background.active"
        }
      },
      tertiary: {
        backgroundColor: "background.tertiary",
        color: "foreground.tertiary",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "background.hover.tertiary",
          color: "foreground.hover.tertiary"
        },
        _active: {
          backgroundColor: "background.active"
        }
      },
      dropdown: {
        backgroundColor: "white",
        color: "base.darkBlue",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "base.blueBrandSecondary"
        },
        _active: {
          backgroundColor: "base.blueBrandTertiary"
        },
        _focus: {
          backgroundColor: "base.blueBrandTertiary"
        }
      },
      text: {
        fontSize: "inherit",
        padding: "0",
        textDecoration: "underline",
        backgroundColor: "transparent",
        color: "foreground.tertiary",
        borderColor: "transparent",
        _hover: {
          color: "base.darkBlue"
        }
      }
    },
    destructive: { true: {} },
    disabled: {
      true: {
        cursor: "not-allowed",
        color: "foreground.disabled",
        backgroundColor: "background.disabled",
        borderColor: "transparent",
        _hover: {
          backgroundColor: "background.disabled",
          color: "foreground.disabled"
        },
        _active: {
          backgroundColor: "background.disabled",
          color: "foreground.disabled"
        }
      }
    },
    full: {
      true: {
        w: "100% !important",
        justifyContent: "center"
      }
    },
    iconOnly: {
      true: {
        p: "sm",
        justifyContent: "center"
      }
    },
    showIconLeft: {
      true: {}
    },
    showIconRight: {
      true: {}
    }
  },
  compoundVariants: [
    {
      variant: "primary",
      destructive: true,
      css: {
        backgroundColor: "background.destructive.primary",
        color: "foreground.destructive.primary",
        borderColor: "border.destructive.primary",
        _hover: {
          backgroundColor: "background.destructive.hover.primary",
          color: "foreground.destructive.hover.primary"
        },
        _active: {
          backgroundColor: "background.destructive.active.primary",
          color: "foreground.destructive.primary"
        }
      }
    },
    {
      variant: "secondary",
      destructive: true,
      css: {
        backgroundColor: "background.destructive.secondary",
        color: "foreground.destructive.secondary",
        borderColor: "border.destructive.secondary",
        _hover: {
          backgroundColor: "background.destructive.hover.secondary",
          color: "foreground.destructive.hover.secondary",
          borderColor: "border.destructive.hover.secondary"
        },
        _active: {
          backgroundColor: "background.destructive.secondary"
        }
      }
    },
    {
      variant: "tertiary",
      destructive: true,
      css: {
        backgroundColor: "background.destructive.tertiary",
        color: "foreground.destructive.tertiary",
        _hover: {
          backgroundColor: "background.destructive.hover.tertiary",
          color: "foreground.destructive.hover.tertiary"
        },
        _active: {
          backgroundColor: "background.destructive.active.tertiary"
        }
      }
    },
    {
      iconOnly: true,
      size: "xsmall",
      css: {
        w: "32px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    },
    {
      iconOnly: true,
      size: "small",
      css: {
        w: "40px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    },
    {
      iconOnly: true,
      size: "medium",
      css: {
        w: "48px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    },
    {
      iconOnly: true,
      size: "large",
      css: {
        w: "56px",
        _disabled: {
          backgroundColor: "transparent"
        }
      }
    },
    {
      showIconLeft: true,
      size: "xsmall",
      css: {
        pl: "2"
      }
    },
    {
      showIconLeft: true,
      size: "small",
      css: {
        pl: "3"
      }
    },
    {
      showIconLeft: true,
      size: "medium",
      css: {
        pr: "6"
      }
    },
    {
      showIconLeft: true,
      size: "large",
      css: {
        pr: "6"
      }
    },
    {
      showIconRight: true,
      size: "xsmall",
      css: {
        pr: "2"
      }
    },
    {
      showIconRight: true,
      size: "small",
      css: {
        pr: "3"
      }
    },
    {
      showIconRight: true,
      size: "medium",
      css: {
        pl: "6"
      }
    },
    {
      showIconRight: true,
      size: "large",
      css: {
        pl: "6"
      }
    }
  ],
  defaultVariants: {
    size: "medium",
    variant: "primary"
  }
});
var ButtonIcon = ({
  icon,
  loading,
  buttonSize = "medium"
}) => /* @__PURE__ */ jsx2(
  Box,
  {
    alignContent: "center",
    h: buttonSize === "xsmall" ? "4" : buttonSize === "small" ? "6" : "8",
    justifyContent: "center",
    w: buttonSize === "xsmall" ? "4" : buttonSize === "small" ? "6" : "8",
    children: /* @__PURE__ */ jsx2(FontAwesomeIcon2, { icon, opacity: loading ? 0 : 1 })
  }
);
var Button = React.forwardRef(
  ({
    children,
    disabled,
    loading,
    iconOnly,
    iconRight,
    iconLeft,
    className,
    full,
    destructive,
    ...buttonProps
  }, ref) => {
    const showIconLeft = iconLeft && !iconOnly;
    const showIconRight = iconRight && !iconOnly;
    const showSingleSpinner = loading && !iconLeft;
    const buttonClass = buttonRecipe({
      ...buttonProps,
      full,
      iconOnly: Boolean(iconOnly),
      destructive,
      disabled: Boolean(disabled ?? loading),
      showIconLeft: Boolean(showIconLeft),
      showIconRight: Boolean(showIconRight)
    });
    return /* @__PURE__ */ jsxs(
      "button",
      {
        className: `${buttonClass} ${className ?? ""}`,
        disabled: disabled ?? loading,
        ref,
        type: "button",
        ...buttonProps,
        children: [
          showSingleSpinner ? /* @__PURE__ */ jsx2(
            Box,
            {
              style: {
                position: "absolute",
                transform: "translateX(-50%)",
                left: "50%"
              },
              children: /* @__PURE__ */ jsx2(Spinner, { size: "xs" })
            }
          ) : null,
          showIconLeft ? /* @__PURE__ */ jsxs(Box, { style: { position: "relative", display: "flex" }, children: [
            /* @__PURE__ */ jsx2(
              ButtonIcon,
              {
                buttonSize: buttonProps.size,
                icon: iconLeft,
                loading
              }
            ),
            loading ? /* @__PURE__ */ jsx2(
              Spinner,
              {
                size: "xs",
                style: {
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: "25%"
                }
              }
            ) : null
          ] }) : null,
          iconOnly ? /* @__PURE__ */ jsx2(
            ButtonIcon,
            {
              buttonSize: buttonProps.size,
              icon: iconOnly,
              loading
            }
          ) : /* @__PURE__ */ jsx2(Box, { style: { opacity: !iconLeft && loading ? 0 : 1 }, children }),
          showIconRight ? /* @__PURE__ */ jsx2(
            ButtonIcon,
            {
              buttonSize: buttonProps.size,
              icon: iconRight,
              loading
            }
          ) : null
        ]
      }
    );
  }
);
Button.displayName = "Button";

// src/AlertDialog/AlertDialog.tsx
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
var alertDialogRecipe = sva({
  className: "alertDialog",
  slots: [
    "root",
    "trigger",
    "portal",
    "overlay",
    "content",
    "header",
    "footer",
    "title",
    "description",
    "action",
    "cancel"
  ],
  base: {
    overlay: {
      position: "fixed",
      inset: "0",
      zIndex: 50,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      // Darker background
      animation: "overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",
      textAlign: "center"
    },
    content: {
      position: "fixed",
      left: "50%",
      top: "50%",
      zIndex: 50,
      p: "6",
      display: "flex",
      flexDirection: "column",
      w: "full",
      maxWidth: "lg",
      maxHeight: "85vh",
      // Limit the maximum height
      transform: "translate(-50%, -50%)",
      border: "base",
      bg: "background",
      boxShadow: "lg",
      animation: "contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",
      "&:focus": { outline: "none" },
      overflow: "hidden",
      alignItems: "center",
      sm: {
        rounded: "lg"
      }
    },
    header: {
      display: "flex",
      flexDirection: "column",
      spaceY: "2",
      textAlign: "center",
      alignItems: "center"
    },
    footer: {
      display: "flex",
      flexDirection: "column-reverse",
      marginTop: "sm",
      sm: {
        marginTop: "xl",
        flexDirection: "row",
        justifyContent: "flex-end",
        spaceX: "md"
      }
    },
    title: {
      textStyle: "lg",
      fontWeight: "semibold"
    },
    description: {
      textStyle: "sm",
      color: "muted.foreground"
    },
    cancel: {
      mt: {
        base: "2",
        sm: "0"
      }
    }
  }
});
var AlertDialog = AlertDialogPrimitive.Root;
var AlertDialogTrigger = AlertDialogPrimitive.Trigger;
var AlertDialogPortal = AlertDialogPrimitive.Portal;
var AlertDialogOverlay = React2.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx3(
  AlertDialogPrimitive.Overlay,
  {
    className: cx(alertDialogRecipe().overlay, className),
    ...props,
    ref
  }
));
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;
var AlertDialogContent = React2.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsxs2(AlertDialogPortal, { children: [
  /* @__PURE__ */ jsx3(AlertDialogOverlay, {}),
  /* @__PURE__ */ jsx3(
    AlertDialogPrimitive.Content,
    {
      className: cx(alertDialogRecipe().content, className),
      ref,
      ...props
    }
  )
] }));
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;
var AlertDialogHeader = React2.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx3(
  "div",
  {
    className: cx(alertDialogRecipe().header, className),
    ref,
    ...props
  }
));
AlertDialogHeader.displayName = "AlertDialogHeader";
var AlertDialogFooter = React2.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx3(
  "div",
  {
    className: cx(alertDialogRecipe().footer, className),
    ref,
    ...props
  }
));
AlertDialogFooter.displayName = "AlertDialogFooter";
var AlertDialogTitle = React2.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx3(
  AlertDialogPrimitive.Title,
  {
    className: cx(alertDialogRecipe().title, className),
    ref,
    ...props
  }
));
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;
var AlertDialogDescription = React2.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx3(
  AlertDialogPrimitive.Description,
  {
    className: cx(alertDialogRecipe().description, className),
    ref,
    ...props
  }
));
AlertDialogDescription.displayName = AlertDialogPrimitive.Description.displayName;
var AlertDialogAction = React2.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx3(AlertDialogPrimitive.Action, { asChild: true, children: /* @__PURE__ */ jsx3(
  Button,
  {
    className: cx(alertDialogRecipe().action, className),
    ref,
    ...props
  }
) }));
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName;
var AlertDialogCancel = React2.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx3(AlertDialogPrimitive.Cancel, { asChild: true, children: /* @__PURE__ */ jsx3(
  Button,
  {
    className: cx(alertDialogRecipe().cancel, className),
    ref,
    variant: "secondary",
    ...props
  }
) }));
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;

// src/AlertDialog/AlertDialog.stories.tsx
import { css as css3 } from "@doowii/design-system/css";

// ../../node_modules/@fortawesome/free-solid-svg-icons/index.mjs
var faCircleExclamation = {
  prefix: "fas",
  iconName: "circle-exclamation",
  icon: [512, 512, ["exclamation-circle"], "f06a", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
};
var faCircleCheck = {
  prefix: "fas",
  iconName: "circle-check",
  icon: [512, 512, [61533, "check-circle"], "f058", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"]
};
var faTrash = {
  prefix: "fas",
  iconName: "trash",
  icon: [448, 512, [], "f1f8", "M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z"]
};
var faCircleInfo = {
  prefix: "fas",
  iconName: "circle-info",
  icon: [512, 512, ["info-circle"], "f05a", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"]
};
var faXmark = {
  prefix: "fas",
  iconName: "xmark",
  icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"]
};
var faClose = faXmark;
var faCheck = {
  prefix: "fas",
  iconName: "check",
  icon: [448, 512, [10003, 10004], "f00c", "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"]
};

// src/CircularIcon/CircularIcon.tsx
import { css as css2 } from "@doowii/design-system/css";
import { Box as Box2 } from "@doowii/design-system/jsx";
import { FontAwesomeIcon as FontAwesomeIcon3 } from "@fortawesome/react-fontawesome";
import { jsx as jsx4 } from "react/jsx-runtime";
var circleStyle = {
  width: "148px",
  height: "148px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "linear-gradient(97.52deg, rgba(246, 205, 60, 0.1) 47.37%, rgba(41, 189, 240, 0.1) 65.41%)",
  border: "2px solid transparent",
  boxShadow: "4px 8px 12px 0px #EFC42814, -4px -8px 12px 0px #2ABDF114",
  position: "relative",
  overflow: "hidden",
  "&:after": {
    content: "''",
    position: "absolute",
    inset: 0,
    borderRadius: "50%",
    padding: "3px",
    width: "full",
    height: "full",
    background: "linear-gradient(97.52deg, #F6CD3C 47.37%, #29BDF0 65.41%)",
    "-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    "-webkit-mask-composite": "xor",
    "mask-composite": "exclude",
    "pointer-events": "none"
  }
};
var iconStyle = css2({
  fontSize: "42px",
  rotate: "10deg"
});
var CircularIcon = ({ icon, css: cssProp }) => /* @__PURE__ */ jsx4(Box2, { css: { ...circleStyle, ...cssProp ?? {} }, children: /* @__PURE__ */ jsx4(FontAwesomeIcon3, { className: iconStyle, icon }) });

// src/AlertDialog/AlertDialog.stories.tsx
import { jsx as jsx5, jsxs as jsxs3 } from "react/jsx-runtime";
var Default = {
  render: () => /* @__PURE__ */ jsxs3(AlertDialog, { children: [
    /* @__PURE__ */ jsx5(AlertDialogTrigger, { asChild: true, children: /* @__PURE__ */ jsx5(Button, { children: "Open Alert Dialog" }) }),
    /* @__PURE__ */ jsxs3(AlertDialogContent, { children: [
      /* @__PURE__ */ jsxs3(AlertDialogHeader, { children: [
        /* @__PURE__ */ jsx5(AlertDialogTitle, { children: "Are you sure?" }),
        /* @__PURE__ */ jsx5(AlertDialogDescription, { children: "This action cannot be undone. This will permanently delete your account and remove your data from our servers." })
      ] }),
      /* @__PURE__ */ jsxs3(AlertDialogFooter, { children: [
        /* @__PURE__ */ jsx5(AlertDialogCancel, { children: "Cancel" }),
        /* @__PURE__ */ jsx5(AlertDialogAction, { children: "Continue" })
      ] })
    ] })
  ] })
};
var WithIcon = {
  render: () => /* @__PURE__ */ jsxs3(AlertDialog, { children: [
    /* @__PURE__ */ jsx5(AlertDialogTrigger, { asChild: true, children: /* @__PURE__ */ jsx5(Button, { children: "Open Alert Dialog with Icon" }) }),
    /* @__PURE__ */ jsxs3(AlertDialogContent, { children: [
      /* @__PURE__ */ jsxs3(AlertDialogHeader, { children: [
        /* @__PURE__ */ jsx5(CircularIcon, { css: { marginBottom: "md" }, icon: faTrash }),
        /* @__PURE__ */ jsx5(AlertDialogTitle, { children: "Delete Chat" }),
        /* @__PURE__ */ jsx5(AlertDialogDescription, { children: "Are you sure you want to delete this chat?" })
      ] }),
      /* @__PURE__ */ jsxs3(AlertDialogFooter, { children: [
        /* @__PURE__ */ jsx5(AlertDialogCancel, { children: "Cancel" }),
        /* @__PURE__ */ jsx5(AlertDialogAction, { destructive: true, children: "Delete" })
      ] })
    ] })
  ] })
};

// src/Avatar/Avatar.tsx
import { css as css4, cva as cva2, cx as cx2 } from "@doowii/design-system/css";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React3 from "react";
import { jsx as jsx6 } from "react/jsx-runtime";
var avatarRecipe = cva2({
  base: {
    pos: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    overflow: "hidden",
    rounded: "full"
  },
  variants: {
    size: {
      small: { w: "24px", h: "24px", textStyle: "paragraph_12" },
      medium: { w: "32px", h: "32px", textStyle: "paragraph_14" },
      large: { w: "40px", h: "40px", textStyle: "paragraph_18" }
    }
  },
  defaultVariants: {
    size: "medium"
  }
});
var Avatar = React3.forwardRef(({ className, size, ...props }, ref) => /* @__PURE__ */ jsx6(
  AvatarPrimitive.Root,
  {
    className: cx2(avatarRecipe({ size }), className),
    ref,
    ...props
  }
));
Avatar.displayName = AvatarPrimitive.Root.displayName;
var AvatarImage = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx6(
  AvatarPrimitive.Image,
  {
    className: cx2(
      css4({ aspectRatio: "square", h: "full", w: "full" }),
      className
    ),
    ref,
    ...props
  }
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;
var AvatarFallback = React3.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx6(
  AvatarPrimitive.Fallback,
  {
    className: cx2(
      css4({
        display: "flex",
        h: "full",
        w: "full",
        alignItems: "center",
        justifyContent: "center",
        rounded: "full",
        bg: "base.blueBrandPrimary",
        color: "white",
        fontWeight: "600"
      }),
      className
    ),
    ref,
    ...props
  }
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

// src/BackgroundBox/BackgroundBox.tsx
import { Box as Box3 } from "@doowii/design-system/jsx";
import { jsx as jsx7, jsxs as jsxs4 } from "react/jsx-runtime";
var BackgroundBox = ({ children, containerProps, ...props }) => /* @__PURE__ */ jsxs4(
  Box3,
  {
    ...containerProps,
    css: {
      position: "relative",
      overflow: "auto",
      ...containerProps?.css
    },
    children: [
      /* @__PURE__ */ jsx7(
        Box3,
        {
          css: {
            position: "fixed",
            background: "#F6F3EB",
            opacity: "20%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            pointerEvents: "none"
          }
        }
      ),
      /* @__PURE__ */ jsx7(
        Box3,
        {
          css: {
            position: "fixed",
            opacity: "6%",
            top: "-15%",
            left: "-15%",
            width: "70%",
            height: "70%",
            borderRadius: "full",
            background: "linear-gradient(274.7deg, #DD6C19 0.76%, #EFC428 77.58%)",
            zIndex: 0,
            filter: "blur(300px)",
            pointerEvents: "none"
          }
        }
      ),
      /* @__PURE__ */ jsx7(
        Box3,
        {
          css: {
            position: "fixed",
            opacity: "6%",
            bottom: "-15%",
            right: "-15%",
            width: "70%",
            height: "70%",
            borderRadius: "full",
            background: "linear-gradient(141.47deg, #3CA0C3 16.12%, #7564E3 74.96%)",
            filter: "blur(300px)",
            zIndex: 0,
            pointerEvents: "none"
          }
        }
      ),
      /* @__PURE__ */ jsx7(
        Box3,
        {
          ...props,
          css: { maxWidth: "100%", zIndex: 1, position: "relative", ...props.css },
          children
        }
      )
    ]
  }
);

// src/Badge/Badge.tsx
import { css as css6, cva as cva3, cx as cx3 } from "@doowii/design-system/css";
import { HStack } from "@doowii/design-system/jsx";
import {
  faArrowUpRightFromSquare,
  faCircleXmark
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon4 } from "@fortawesome/react-fontawesome";
import React5 from "react";

// src/Tooltip/Tooltip.tsx
import { css as css5 } from "@doowii/design-system/css";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { forwardRef as forwardRef3 } from "react";
import { jsx as jsx8, jsxs as jsxs5 } from "react/jsx-runtime";
var tooltipContentStyles = css5({
  bg: "base.darkBlue",
  color: "base.white",
  rounded: "xs",
  padding: "sm",
  textStyle: "paragraph_14",
  zIndex: 9999999,
  maxWidth: "400px"
});
var tooltipArrowStyles = css5({
  fill: "base.darkBlue"
});
var Tooltip = forwardRef3(
  ({ open, children, side, sideOffset, align, content, ...triggerProps }, ref) => /* @__PURE__ */ jsx8(TooltipPrimitive.Provider, { delayDuration: 200, children: /* @__PURE__ */ jsxs5(TooltipPrimitive.Root, { open, children: [
    /* @__PURE__ */ jsx8(TooltipPrimitive.Trigger, { asChild: true, ...triggerProps, ref, children }),
    /* @__PURE__ */ jsxs5(
      TooltipPrimitive.Content,
      {
        align,
        className: tooltipContentStyles,
        side,
        sideOffset,
        children: [
          content,
          /* @__PURE__ */ jsx8(TooltipPrimitive.Arrow, { className: tooltipArrowStyles })
        ]
      }
    )
  ] }) })
);
Tooltip.displayName = "Tooltip";

// src/Badge/Badge.tsx
import { jsx as jsx9, jsxs as jsxs6 } from "react/jsx-runtime";
var badgeStyles = cva3({
  base: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    rounded: "full",
    p: "1",
    pl: "3",
    textStyle: "paragraph_14",
    fontWeight: "medium",
    gap: "3",
    backgroundColor: "base.lightherGray"
  },
  variants: {
    hasIcons: {
      true: {
        alignItems: "space-between"
      }
    }
  },
  defaultVariants: {
    hasIcons: true
  }
});
var Badge = React5.forwardRef(
  ({ children, className, withIcons = true, onNavigate, onRemove, ...props }, ref) => /* @__PURE__ */ jsxs6(
    "span",
    {
      className: cx3(
        badgeStyles({
          hasIcons: withIcons
        }),
        className
      ),
      ...props,
      ref,
      children: [
        children,
        withIcons ? /* @__PURE__ */ jsxs6(HStack, { gap: "none", children: [
          /* @__PURE__ */ jsx9(Tooltip, { content: "Navigate to the Pin", children: /* @__PURE__ */ jsx9(
            FontAwesomeIcon4,
            {
              className: css6({
                w: "6",
                h: "6",
                cursor: "pointer"
              }),
              icon: faArrowUpRightFromSquare,
              onClick: onNavigate,
              size: "sm"
            }
          ) }),
          /* @__PURE__ */ jsx9(Tooltip, { content: "Remove from Pinboard", children: /* @__PURE__ */ jsx9(
            FontAwesomeIcon4,
            {
              className: css6({
                w: "6",
                h: "6",
                cursor: "pointer"
              }),
              icon: faCircleXmark,
              onClick: onRemove,
              size: "sm"
            }
          ) })
        ] }) : null
      ]
    }
  )
);
Badge.displayName = "Badge";

// src/Breadcrumb/Breadcrumb.tsx
import { css as css7, cx as cx4 } from "@doowii/design-system/css";
import { faChevronRight, faEllipsis } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon5 } from "@fortawesome/react-fontawesome";

// ../../node_modules/@radix-ui/react-slot/dist/index.mjs
import * as React7 from "react";

// ../../node_modules/@radix-ui/react-compose-refs/dist/index.mjs
import * as React6 from "react";
function setRef(ref, value) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref !== null && ref !== void 0) {
    ref.current = value;
  }
}
function composeRefs(...refs) {
  return (node) => refs.forEach((ref) => setRef(ref, node));
}
function useComposedRefs(...refs) {
  return React6.useCallback(composeRefs(...refs), refs);
}

// ../../node_modules/@radix-ui/react-slot/dist/index.mjs
import { Fragment, jsx as jsx10 } from "react/jsx-runtime";
var Slot = React7.forwardRef((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  const childrenArray = React7.Children.toArray(children);
  const slottable = childrenArray.find(isSlottable);
  if (slottable) {
    const newElement = slottable.props.children;
    const newChildren = childrenArray.map((child) => {
      if (child === slottable) {
        if (React7.Children.count(newElement) > 1) return React7.Children.only(null);
        return React7.isValidElement(newElement) ? newElement.props.children : null;
      } else {
        return child;
      }
    });
    return /* @__PURE__ */ jsx10(SlotClone, { ...slotProps, ref: forwardedRef, children: React7.isValidElement(newElement) ? React7.cloneElement(newElement, void 0, newChildren) : null });
  }
  return /* @__PURE__ */ jsx10(SlotClone, { ...slotProps, ref: forwardedRef, children });
});
Slot.displayName = "Slot";
var SlotClone = React7.forwardRef((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  if (React7.isValidElement(children)) {
    const childrenRef = getElementRef(children);
    return React7.cloneElement(children, {
      ...mergeProps(slotProps, children.props),
      // @ts-ignore
      ref: forwardedRef ? composeRefs(forwardedRef, childrenRef) : childrenRef
    });
  }
  return React7.Children.count(children) > 1 ? React7.Children.only(null) : null;
});
SlotClone.displayName = "SlotClone";
var Slottable = ({ children }) => {
  return /* @__PURE__ */ jsx10(Fragment, { children });
};
function isSlottable(child) {
  return React7.isValidElement(child) && child.type === Slottable;
}
function mergeProps(slotProps, childProps) {
  const overrideProps = { ...childProps };
  for (const propName in childProps) {
    const slotPropValue = slotProps[propName];
    const childPropValue = childProps[propName];
    const isHandler = /^on[A-Z]/.test(propName);
    if (isHandler) {
      if (slotPropValue && childPropValue) {
        overrideProps[propName] = (...args) => {
          childPropValue(...args);
          slotPropValue(...args);
        };
      } else if (slotPropValue) {
        overrideProps[propName] = slotPropValue;
      }
    } else if (propName === "style") {
      overrideProps[propName] = { ...slotPropValue, ...childPropValue };
    } else if (propName === "className") {
      overrideProps[propName] = [slotPropValue, childPropValue].filter(Boolean).join(" ");
    }
  }
  return { ...slotProps, ...overrideProps };
}
function getElementRef(element) {
  let getter = Object.getOwnPropertyDescriptor(element.props, "ref")?.get;
  let mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.ref;
  }
  getter = Object.getOwnPropertyDescriptor(element, "ref")?.get;
  mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.props.ref;
  }
  return element.props.ref || element.ref;
}

// src/Breadcrumb/Breadcrumb.tsx
import * as React8 from "react";
import { jsx as jsx11, jsxs as jsxs7 } from "react/jsx-runtime";
var Breadcrumb = React8.forwardRef(({ ...props }, ref) => /* @__PURE__ */ jsx11("nav", { "aria-label": "breadcrumb", ref, ...props }));
Breadcrumb.displayName = "Breadcrumb";
var BreadcrumbList = React8.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx11(
  "ol",
  {
    className: cx4(
      css7({
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "1.5",
        overflowWrap: "break-word",
        textStyle: "paragraph_16",
        fontWeight: "500",
        lineHeight: "sm",
        sm: { gap: "2.5" }
      }),
      className
    ),
    ref,
    ...props
  }
));
BreadcrumbList.displayName = "BreadcrumbList";
var BreadcrumbItem = React8.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx11(
  "li",
  {
    className: cx4(
      css7({ display: "inline-flex", alignItems: "center", gap: "1.5" }),
      className
    ),
    ref,
    ...props
  }
));
BreadcrumbItem.displayName = "BreadcrumbItem";
var BreadcrumbLink = React8.forwardRef(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : "a";
  return /* @__PURE__ */ jsx11(
    Comp,
    {
      className: cx4(
        css7({
          transitionProperty: "color, background-color, border-color, text-decoration-color, fill, stroke",
          transitionTimingFunction: "colors",
          transitionDuration: "colors"
        }),
        className
      ),
      ref,
      ...props
    }
  );
});
BreadcrumbLink.displayName = "BreadcrumbLink";
var BreadcrumbPage = React8.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx11(
  "span",
  {
    "aria-current": "page",
    "aria-disabled": "true",
    className: cx4(css7({ fontWeight: "normal" }), className),
    ref,
    role: "link",
    ...props
  }
));
BreadcrumbPage.displayName = "BreadcrumbPage";
var BreadcrumbSeparator = ({
  children,
  className,
  ...props
}) => /* @__PURE__ */ jsx11(
  "li",
  {
    "aria-hidden": "true",
    className: cx4("[&>svg]:size-3.5", className),
    role: "presentation",
    ...props,
    children: children ?? /* @__PURE__ */ jsx11(FontAwesomeIcon5, { icon: faChevronRight })
  }
);
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";
var BreadcrumbEllipsis = ({
  className,
  ...props
}) => /* @__PURE__ */ jsxs7(
  "span",
  {
    "aria-hidden": "true",
    className: cx4(
      css7({
        display: "flex",
        h: "9",
        w: "9",
        alignItems: "center",
        justifyContent: "center"
      }),
      className
    ),
    role: "presentation",
    ...props,
    children: [
      /* @__PURE__ */ jsx11(FontAwesomeIcon5, { className: css7({ h: "4", w: "4" }), icon: faEllipsis }),
      /* @__PURE__ */ jsx11(
        "span",
        {
          className: css7({
            pos: "absolute",
            w: "sr.only",
            h: "sr.only",
            p: "sr.only",
            m: "sr.only",
            overflow: "hidden",
            clip: "rect(0, 0, 0, 0)",
            whiteSpace: "nowrap",
            borderWidth: "0"
          }),
          children: "More"
        }
      )
    ]
  }
);
BreadcrumbEllipsis.displayName = "BreadcrumbElipssis";

// src/Callout/Callout.tsx
import React9 from "react";
import { css as css8 } from "@doowii/design-system/css";
import { FontAwesomeIcon as FontAwesomeIcon6 } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck as faCircleCheck2,
  faCircleExclamation as faCircleExclamation2,
  faCircleInfo as faCircleInfo2,
  faTriangleExclamation
} from "@fortawesome/pro-regular-svg-icons";
import { jsx as jsx12, jsxs as jsxs8 } from "react/jsx-runtime";
var variantConfig = {
  success: {
    icon: faCircleCheck2,
    background: "#E0F5E9",
    color: "#185332"
  },
  warning: {
    icon: faTriangleExclamation,
    background: "#F5EBE0",
    color: "#6B4212"
  },
  info: {
    icon: faCircleInfo2,
    background: "#E0ECF5",
    color: "#204C6C"
  },
  error: {
    icon: faCircleExclamation2,
    background: "#F8DDDD",
    color: "#5D1313"
  }
};
var sizeConfig = {
  "1": {
    padding: "sm",
    gap: "xs",
    iconSize: "1x",
    textStyle: "paragraph_12"
  },
  "2": {
    padding: "md",
    gap: "sm",
    iconSize: "lg",
    textStyle: "paragraph_14"
  },
  "3": {
    padding: "ld",
    gap: "md",
    iconSize: "2x",
    textStyle: "paragraph_16"
  }
};
var Callout = React9.forwardRef(
  ({ variant, size = "2", children, fullWidth = false }, ref) => {
    const variantStyle = variantConfig[variant];
    const sizeStyle = sizeConfig[size];
    return /* @__PURE__ */ jsxs8(
      "div",
      {
        ref,
        style: {
          backgroundColor: variantStyle.background,
          color: variantStyle.color
        },
        className: css8({
          display: "inline-flex",
          // Changed to inline-flex
          width: fullWidth ? "100%" : "auto",
          // Control width based on fullWidth prop
          maxWidth: "100%",
          // Ensure it doesn't overflow container
          alignItems: "center",
          boxShadow: "regular",
          transition: "all 0.2s ease-in-out",
          borderRadius: "md",
          padding: sizeStyle.padding,
          gap: sizeStyle.gap,
          _hover: {
            boxShadow: "hover"
          }
        }),
        role: variant === "error" ? "alert" : "status",
        children: [
          /* @__PURE__ */ jsx12(
            "div",
            {
              className: css8({
                display: "flex",
                alignItems: "center",
                flexShrink: 0
              }),
              children: /* @__PURE__ */ jsx12(FontAwesomeIcon6, { icon: variantStyle.icon, size: sizeStyle.iconSize, "aria-hidden": "true" })
            }
          ),
          /* @__PURE__ */ jsx12(
            "div",
            {
              className: css8({
                flex: "none",
                // Changed to none to prevent expanding
                textStyle: sizeStyle.textStyle,
                display: "block",
                // Changed to block for proper text wrapping
                whiteSpace: fullWidth ? "normal" : "nowrap"
                // Control text wrapping
              }),
              children
            }
          )
        ]
      }
    );
  }
);
Callout.displayName = "Callout";

// src/Cards/Card.tsx
import { css as css9, cva as cva4 } from "@doowii/design-system/css";
import React10 from "react";
var cardRecipe = cva4({
  base: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "ld",
    bg: "base.white",
    p: "ld",
    rounded: "xl",
    border: "1px solid token(colors.base.lightGray)",
    shadow: "regular",
    w: "full",
    textAlign: "left",
    color: "base.darkBlue"
  },
  variants: {
    variant: {
      default: {},
      button: {
        cursor: "pointer",
        _hover: {
          shadow: "hover"
        }
      },
      flat: {
        shadow: "none",
        _hover: {
          shadow: "none"
        }
      }
    }
  },
  defaultVariants: {
    variant: "default"
  }
});
var Card = React10.forwardRef(
  ({ children, variant, label, onClick, css: cssProp, ...props }, ref) => {
    const cardClass = css9(cardRecipe.raw({ variant }), cssProp);
    const CardComponent = variant === "button" ? "button" : "div";
    return React10.createElement(
      CardComponent,
      {
        "aria-label": label,
        className: cardClass,
        onClick: variant === "button" ? onClick : void 0,
        ref,
        type: variant === "button" ? "button" : void 0,
        ...props
      },
      children
    );
  }
);
Card.displayName = "Card";

// src/Checkbox/Checkbox.tsx
import { css as css10, cx as cx5 } from "@doowii/design-system/css";
import { Box as Box4, Flex } from "@doowii/design-system/jsx";
import { FontAwesomeIcon as FontAwesomeIcon7 } from "@fortawesome/react-fontawesome";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React11 from "react";
import { jsx as jsx13, jsxs as jsxs9 } from "react/jsx-runtime";
var Checkbox = React11.forwardRef(({ className, variant = "primary", secondaryLabel, ...props }, ref) => /* @__PURE__ */ jsxs9(
  Flex,
  {
    className: cx5(
      css10({
        alignItems: variant === "expanded" ? "center" : "flex-start",
        gap: "sm",
        p: variant === "expanded" ? "md" : "sm",
        pr: "md",
        width: variant === "expanded" ? "100%" : "fit-content",
        bg: variant === "secondary" ? "base.blueBrandSecondary" : "base.white",
        color: variant === "secondary" ? "base.blueBrandPrimary" : "base.darkBlue",
        rounded: variant === "expanded" ? "20px" : "sm",
        shadow: variant === "expanded" ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none"
      }),
      className
    ),
    children: [
      /* @__PURE__ */ jsx13(
        CheckboxPrimitive.Root,
        {
          className: cx5(
            css10({
              cursor: "pointer",
              h: "4",
              w: "4",
              rounded: "3px",
              borderWidth: "2px",
              _focusVisible: { ring: "none", ringOffset: "none", shadow: "1" },
              _disabled: { cursor: "not-allowed", opacity: "0.5" },
              _checked: {
                bg: variant === "secondary" ? "base.blueBrandPrimary" : "#117DA3",
                borderColor: variant === "secondary" ? "base.blueBrandPrimary" : "#117DA3",
                color: "white"
              }
            })
          ),
          ref,
          ...props,
          children: /* @__PURE__ */ jsx13(
            CheckboxPrimitive.Indicator,
            {
              className: cx5(
                css10({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "current"
                })
              ),
              children: /* @__PURE__ */ jsx13(FontAwesomeIcon7, { icon: faCheck, size: "xs" })
            }
          )
        }
      ),
      variant === "expanded" ? /* @__PURE__ */ jsxs9(Flex, { direction: "column", children: [
        /* @__PURE__ */ jsx13(
          "label",
          {
            className: css10({
              textStyle: "label_16",
              lineHeight: "none",
              fontWeight: "bold",
              _peerDisabled: { cursor: "not-allowed", opacity: "0.7" }
            }),
            htmlFor: props.id,
            children: props.label
          }
        ),
        secondaryLabel ? /* @__PURE__ */ jsx13(
          Box4,
          {
            css: {
              textStyle: "label_14",
              color: "base.gray",
              mt: "2px"
            },
            children: secondaryLabel
          }
        ) : null
      ] }) : /* @__PURE__ */ jsx13(
        "label",
        {
          className: css10({
            textStyle: "label_16",
            lineHeight: "none",
            fontWeight: "medium",
            _peerDisabled: { cursor: "not-allowed", opacity: "0.7" }
          }),
          htmlFor: props.id,
          children: props.label
        }
      )
    ]
  }
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

// src/CircularCounter/CircularCounter.tsx
import { Box as Box5 } from "@doowii/design-system/jsx";

// src/Label/Label.tsx
import { cva as cva5, cx as cx6 } from "@doowii/design-system/css";
import { jsx as jsx14 } from "react/jsx-runtime";
var labelRecipe = cva5({
  base: {
    fontWeight: 500
  },
  variants: {
    level: {
      1: {
        textStyle: "label_14"
      },
      2: {
        textStyle: "label_16"
      },
      3: {
        textStyle: "label_18"
      },
      4: {
        textStyle: "label_20"
      }
    },
    variant: {
      primary: {
        color: "foreground.base"
      },
      constrast: {
        color: "base.blueBrandPrimary"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    level: 2
  }
});
var Label = ({
  level,
  variant,
  children,
  ...props
}) => /* @__PURE__ */ jsx14(
  "label",
  {
    ...props,
    className: cx6(labelRecipe({ level, variant }), props.className),
    children
  }
);

// src/CircularCounter/CircularCounter.tsx
import { jsx as jsx15 } from "react/jsx-runtime";
var circleStyle2 = {
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px solid transparent",
  position: "relative",
  overflow: "hidden",
  "&:after": {
    content: "''",
    position: "absolute",
    inset: 0,
    borderRadius: "50%",
    padding: "3px",
    width: "full",
    height: "full",
    background: "linear-gradient(97.52deg, #F6CD3C 47.37%, #29BDF0 65.41%)",
    "-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    "-webkit-mask-composite": "xor",
    "mask-composite": "exclude",
    "pointer-events": "none"
  }
};
var CircularCounter = ({ counter }) => /* @__PURE__ */ jsx15(Box5, { css: circleStyle2, children: /* @__PURE__ */ jsx15(Label, { level: counter.toString().length > 2 ? 1 : 2, children: counter }) });

// src/ConfirmationDialog/ConfirmationDialog.tsx
import { css as css13 } from "@doowii/design-system/css";
import { Flex as Flex2 } from "@doowii/design-system/jsx";
import { DialogTitle as DialogTitle2 } from "@radix-ui/react-dialog";

// ../../node_modules/@radix-ui/react-visually-hidden/dist/index.mjs
import * as React13 from "react";

// ../../node_modules/@radix-ui/react-primitive/dist/index.mjs
import * as React12 from "react";
import * as ReactDOM from "react-dom";
import { jsx as jsx16 } from "react/jsx-runtime";
var NODES = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
];
var Primitive = NODES.reduce((primitive, node) => {
  const Node = React12.forwardRef((props, forwardedRef) => {
    const { asChild, ...primitiveProps } = props;
    const Comp = asChild ? Slot : node;
    if (typeof window !== "undefined") {
      window[Symbol.for("radix-ui")] = true;
    }
    return /* @__PURE__ */ jsx16(Comp, { ...primitiveProps, ref: forwardedRef });
  });
  Node.displayName = `Primitive.${node}`;
  return { ...primitive, [node]: Node };
}, {});

// ../../node_modules/@radix-ui/react-visually-hidden/dist/index.mjs
import { jsx as jsx17 } from "react/jsx-runtime";
var NAME = "VisuallyHidden";
var VisuallyHidden = React13.forwardRef(
  (props, forwardedRef) => {
    return /* @__PURE__ */ jsx17(
      Primitive.span,
      {
        ...props,
        ref: forwardedRef,
        style: {
          // See: https://github.com/twbs/bootstrap/blob/master/scss/mixins/_screen-reader.scss
          position: "absolute",
          border: 0,
          width: 1,
          height: 1,
          padding: 0,
          margin: -1,
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          wordWrap: "normal",
          ...props.style
        }
      }
    );
  }
);
VisuallyHidden.displayName = NAME;
var Root5 = VisuallyHidden;

// src/Dialog/Dialog.tsx
import { css as css11, cx as cx7, sva as sva2 } from "@doowii/design-system/css";
import { FontAwesomeIcon as FontAwesomeIcon8 } from "@fortawesome/react-fontawesome";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React14 from "react";
import { jsx as jsx18, jsxs as jsxs10 } from "react/jsx-runtime";
var dialogSlotRecipe = sva2({
  className: "dialog",
  slots: [
    "root",
    "trigger",
    "portal",
    "overlay",
    "close",
    "content",
    "header",
    "body",
    "footer",
    "title",
    "description",
    "actionButtons"
  ],
  base: {
    overlay: {
      position: "fixed",
      inset: "0",
      zIndex: 50,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      // Darker background
      animation: "overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)"
    },
    close: {
      position: "absolute",
      right: "6",
      top: "5",
      rounded: "sm",
      transition: "opacity",
      cursor: "pointer",
      _hover: {
        opacity: "1"
      },
      _focus: {
        outline: "2px solid transparent",
        outlineOffset: "2px"
      },
      _disabled: {
        pointerEvents: "none"
      }
    },
    content: {
      position: "fixed",
      left: "50%",
      top: "50%",
      zIndex: 50,
      display: "flex",
      flexDirection: "column",
      w: "lg",
      maxHeight: "85vh",
      // Limit the maximum height
      transform: "translate(-50%, -50%)",
      border: "base",
      bg: "background",
      boxShadow: "lg",
      animation: "contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",
      "&:focus": { outline: "none" },
      overflow: "hidden",
      rounded: "lg"
    },
    header: {
      display: "flex",
      flexDirection: "column",
      paddingX: "6",
      paddingY: "4",
      borderBottom: "1px solid",
      borderColor: "gray.200",
      flexShrink: 0
      // Prevent header from shrinking
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textStyle: "lg",
      fontWeight: "semibold",
      marginBottom: "2",
      paddingRight: "8"
    },
    description: {
      textStyle: "sm",
      color: "muted.foreground"
    },
    actionButtons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      spaceX: "2",
      cursor: "pointer"
    },
    body: {
      flex: "1 1 auto",
      overflowY: "auto",
      padding: "6"
    },
    footer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingX: "6",
      paddingY: "6",
      borderTop: "1px solid",
      borderColor: "gray.200",
      flexShrink: 0,
      // Prevent footer from shrinking
      spaceX: "2",
      sm: {
        flexDirection: "row",
        justifyContent: "flex-end"
      }
    }
  },
  variants: {
    variant: {
      default: {},
      slideRight: {
        content: {
          left: "auto",
          right: "0",
          top: "0",
          bottom: "0",
          maxWidth: "80%",
          height: "100%",
          maxHeight: "100vh",
          transform: "none",
          animation: "slideInFromRight 300ms cubic-bezier(0.16, 1, 0.3, 1)"
        }
      },
      fullScreen: {
        content: {
          position: "fixed",
          top: "50%",
          left: "50%",
          maxHeight: "85vh",
          maxWidth: "85vw",
          width: "max-content"
        }
      }
    }
  }
});
var Dialog = DialogPrimitive.Root;
var DialogTrigger = DialogPrimitive.Trigger;
var DialogPortal = DialogPrimitive.Portal;
var DialogOverlay = React14.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx18(
  DialogPrimitive.Overlay,
  {
    className: cx7(dialogSlotRecipe().overlay, className),
    ref,
    ...props
  }
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
var DialogClose = React14.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx18(
  DialogPrimitive.Close,
  {
    className: cx7(dialogSlotRecipe().close, className),
    ref,
    ...props
  }
));
DialogClose.displayName = DialogPrimitive.Close.displayName;
var DialogContent = React14.forwardRef(({ children, className, variant = "default", ...props }, ref) => {
  const styles = dialogSlotRecipe({ variant });
  return /* @__PURE__ */ jsxs10(DialogPortal, { children: [
    /* @__PURE__ */ jsx18(DialogOverlay, {}),
    /* @__PURE__ */ jsxs10(
      DialogPrimitive.Content,
      {
        className: cx7(styles.content, className),
        ref,
        ...props,
        children: [
          children,
          /* @__PURE__ */ jsxs10(DialogClose, { children: [
            /* @__PURE__ */ jsx18(FontAwesomeIcon8, { icon: faXmark, size: "lg" }),
            /* @__PURE__ */ jsx18("span", { className: css11({ srOnly: true }), children: "Close" })
          ] })
        ]
      }
    )
  ] });
});
DialogContent.displayName = DialogPrimitive.Content.displayName;
var DialogHeader = React14.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx18(
  "div",
  {
    className: cx7(dialogSlotRecipe().header, className),
    ref,
    ...props
  }
));
DialogHeader.displayName = "DialogHeader";
var DialogFooter = React14.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx18(
  "div",
  {
    className: cx7(dialogSlotRecipe().footer, className),
    ref,
    ...props
  }
));
DialogFooter.displayName = "DialogFooter";
var DialogTitle = React14.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx18(
  DialogPrimitive.Title,
  {
    className: cx7(dialogSlotRecipe().title, className),
    ref,
    ...props
  }
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;
var DialogDescription = React14.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx18(
  DialogPrimitive.Description,
  {
    className: cx7(dialogSlotRecipe().description, className),
    ref,
    ...props
  }
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;
var DialogBody = React14.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx18(
  "div",
  {
    className: cx7(dialogSlotRecipe().body, className),
    ref,
    ...props
  }
));
DialogBody.displayName = "DialogBody";
var DialogActionButtons = React14.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx18(
  "div",
  {
    className: cx7(dialogSlotRecipe().actionButtons, className),
    ref,
    ...props
  }
));
DialogActionButtons.displayName = "DialogActionButtons";

// src/Heading/Heading.tsx
import { css as css12, cva as cva6 } from "@doowii/design-system/css";
import { jsx as jsx19 } from "react/jsx-runtime";
var headingRecipe = cva6({
  base: {
    fontWeight: 600
  },
  variants: {
    level: {
      1: {
        textStyle: "title_h1"
      },
      2: {
        textStyle: "title_h2"
      },
      3: {
        textStyle: "title_h3"
      }
    },
    variant: {
      primary: {
        color: "foreground.base"
      },
      constrast: {
        color: "base.blueBrandPrimary"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    level: 2
  }
});
var Heading = ({
  level = 2,
  variant,
  as,
  children,
  css: cssProp,
  component,
  ...props
}) => {
  const Tag = as ?? `h${level}`;
  const RenderComponent = component ?? Tag;
  return /* @__PURE__ */ jsx19(
    RenderComponent,
    {
      className: css12(headingRecipe.raw({ level, variant }), cssProp),
      ...props,
      children
    }
  );
};

// src/Text/Text.tsx
import { cva as cva7, cx as cx8 } from "@doowii/design-system/css";
import { jsx as jsx20 } from "react/jsx-runtime";
var textRecipe = cva7({
  base: {},
  variants: {
    level: {
      1: {
        textStyle: "paragraph_12"
      },
      2: {
        textStyle: "paragraph_14"
      },
      3: {
        textStyle: "paragraph_16"
      },
      4: {
        textStyle: "paragraph_18"
      }
    },
    variant: {
      primary: {
        color: "foreground.base"
      },
      constrast: {
        color: "base.blueBrandPrimary"
      }
    }
  },
  defaultVariants: {
    variant: "primary",
    level: 2
  }
});
var Text = ({
  level,
  variant,
  className,
  children,
  ...props
}) => /* @__PURE__ */ jsx20("p", { className: cx8(textRecipe({ level, variant }), className), ...props, children });

// src/ConfirmationDialog/ConfirmationDialog.tsx
import { jsx as jsx21, jsxs as jsxs11 } from "react/jsx-runtime";
var ConfirmationDialog = ({
  isOpen,
  title,
  description,
  confirmationButtonText,
  cancelButtonText,
  isLoadingAction,
  icon,
  destructive,
  setIsOpen,
  onConfirm,
  onCancel
}) => /* @__PURE__ */ jsx21(Dialog, { onOpenChange: setIsOpen, open: isOpen, children: /* @__PURE__ */ jsxs11(DialogContent, { onClick: (e) => e.stopPropagation(), variant: "default", children: [
  /* @__PURE__ */ jsx21(Root5, { children: /* @__PURE__ */ jsx21(DialogTitle2, { children: "Confirmation" }) }),
  /* @__PURE__ */ jsx21(DialogBody, { children: /* @__PURE__ */ jsxs11(
    Flex2,
    {
      css: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "md"
      },
      children: [
        icon ? /* @__PURE__ */ jsx21(CircularIcon, { icon }) : null,
        /* @__PURE__ */ jsxs11(
          Flex2,
          {
            css: {
              alignItems: "center",
              flexDirection: "column",
              gap: "xs"
            },
            children: [
              /* @__PURE__ */ jsx21(Heading, { level: 2, children: title }),
              description ? /* @__PURE__ */ jsx21(Text, { level: 3, children: description }) : null
            ]
          }
        )
      ]
    }
  ) }),
  /* @__PURE__ */ jsxs11(
    DialogFooter,
    {
      className: css13({
        display: "flex",
        justifyContent: "center!",
        border: "none",
        p: "xl2",
        gap: "sm"
      }),
      children: [
        /* @__PURE__ */ jsx21(
          Button,
          {
            destructive,
            loading: isLoadingAction,
            onClick: onConfirm,
            size: "large",
            variant: "primary",
            children: confirmationButtonText ?? "Confirm"
          }
        ),
        /* @__PURE__ */ jsx21(
          Button,
          {
            onClick: (e) => onCancel ? onCancel(e) : setIsOpen(false),
            size: "large",
            variant: "secondary",
            children: cancelButtonText ?? "Cancel"
          }
        )
      ]
    }
  )
] }) });

// src/Dropdown/Dropdown.tsx
import { css as css14, cx as cx9 } from "@doowii/design-system/css";
import {
  faCheck as faCheck2,
  faChevronRight as faChevronRight2,
  faPeriod
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon9 } from "@fortawesome/react-fontawesome";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import * as React15 from "react";
import { jsx as jsx22, jsxs as jsxs12 } from "react/jsx-runtime";
var DropdownMenu = DropdownMenuPrimitive.Root;
var DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
var DropdownMenuGroup = DropdownMenuPrimitive.Group;
var DropdownMenuPortal = DropdownMenuPrimitive.Portal;
var DropdownMenuSub = DropdownMenuPrimitive.Sub;
var DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
var DropdownMenuSubTrigger = React15.forwardRef(({ className, inset, children, ...props }, ref) => /* @__PURE__ */ jsxs12(
  DropdownMenuPrimitive.SubTrigger,
  {
    className: cx9(
      css14({
        display: "flex",
        cursor: "default",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "3",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      inset && css14({ pl: "8" }),
      className
    ),
    ref,
    ...props,
    children: [
      children,
      /* @__PURE__ */ jsx22(
        FontAwesomeIcon9,
        {
          className: css14({ ml: "auto", h: "4", w: "4" }),
          icon: faChevronRight2,
          size: "xs"
        }
      )
    ]
  }
));
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;
var DropdownMenuSubContent = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx22(
  DropdownMenuPrimitive.SubContent,
  {
    className: cx9(
      css14({
        zIndex: 50,
        minW: "8rem",
        overflow: "hidden",
        rounded: "md",
        p: "1",
        shadow: "0px 4px 20px 0px #1539462E",
        bg: "white",
        color: "foreground.base",
        textStyle: "paragraph_16",
        ml: "2"
      }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;
var DropdownMenuContent = React15.forwardRef(({ className, sideOffset = 4, ...props }, ref) => /* @__PURE__ */ jsx22(DropdownMenuPrimitive.Portal, { children: /* @__PURE__ */ jsx22(
  DropdownMenuPrimitive.Content,
  {
    className: cx9(
      css14({
        zIndex: 50,
        minW: "8rem",
        overflow: "hidden",
        rounded: "md",
        p: "1",
        shadow: "0px 4px 20px 0px #1539462E",
        bg: "white",
        color: "foreground.base",
        textStyle: "paragraph_16"
      }),
      className
    ),
    ref,
    sideOffset,
    ...props
  }
) }));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;
var DropdownMenuItem = React15.forwardRef(({ className, inset, ...props }, ref) => /* @__PURE__ */ jsx22(
  DropdownMenuPrimitive.Item,
  {
    className: cx9(
      css14({
        pos: "relative",
        display: "flex",
        gap: "4",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "12px",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      inset && css14({ pl: "8" }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
var DropdownMenuCheckboxItem = React15.forwardRef(({ className, children, checked, ...props }, ref) => /* @__PURE__ */ jsxs12(
  DropdownMenuPrimitive.CheckboxItem,
  {
    checked,
    className: cx9(
      css14({
        pos: "relative",
        display: "flex",
        gap: "2",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "12px",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx22(
        FontAwesomeIcon9,
        {
          className: css14({ h: "4", w: "4" }),
          icon: faCheck2,
          size: "xs"
        }
      ),
      children
    ]
  }
));
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;
var DropdownMenuRadioItem = React15.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs12(
  DropdownMenuPrimitive.RadioItem,
  {
    className: cx9(
      css14({
        pos: "relative",
        display: "flex",
        gap: "2",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        p: "12px",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        _hover: { bg: "base.blueBrandSecondary" },
        _focus: { bg: "base.blueBrandSecondary" },
        _active: { bg: "base.blueBrandTertiary" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx22(
        FontAwesomeIcon9,
        {
          className: css14({ h: "4", w: "4", mb: "3" }),
          icon: faPeriod
        }
      ),
      children
    ]
  }
));
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;
var DropdownMenuLabel = React15.forwardRef(({ className, inset, ...props }, ref) => /* @__PURE__ */ jsx22(
  DropdownMenuPrimitive.Label,
  {
    className: cx9(
      css14({
        pl: "2",
        pr: "2",
        pt: "1.5",
        pb: "1.5",
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "semibold"
      }),
      inset && css14({ pl: "8" }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;
var DropdownMenuSeparator = React15.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx22(
  DropdownMenuPrimitive.Separator,
  {
    className: cx9(
      css14({
        ml: ".mx-1",
        mr: ".mx-1",
        mt: "1",
        mb: "1",
        h: "1px",
        bg: "base.lightGray"
      }),
      className
    ),
    ref,
    ...props
  }
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;
var DropdownMenuShortcut = ({
  className,
  ...props
}) => /* @__PURE__ */ jsx22(
  "span",
  {
    className: cx9(
      css14({
        ml: "auto",
        fontSize: "xs",
        letterSpacing: "widest",
        opacity: "0.6"
      }),
      className
    ),
    ...props
  }
);
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";

// src/FeedbackButton/FeedbackButton.tsx
import { cva as cva8 } from "@doowii/design-system/css";
import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid
} from "@fortawesome/pro-solid-svg-icons";
import React16, { useState } from "react";
import { jsx as jsx23 } from "react/jsx-runtime";
var feedBackButtonRecipe = cva8({
  variants: {
    type: {
      positive: {
        color: "complementary.greenPrimary",
        backgroundColor: "white",
        _hover: {
          color: "complementary.greenPrimary",
          backgroundColor: "complementary.greenSecondary"
        }
      },
      negative: {
        color: "complementary.destructivePrimary",
        backgroundColor: "white",
        _hover: {
          color: "complementary.destructivePrimary",
          backgroundColor: "complementary.destructiveSecondary"
        }
      }
    },
    isActive: { true: {} }
  },
  compoundVariants: [
    {
      type: "positive",
      isActive: true,
      css: {
        color: "complementary.greenPrimary",
        backgroundColor: "complementary.greenSecondary"
      }
    },
    {
      type: "negative",
      isActive: true,
      css: {
        color: "complementary.destructivePrimary",
        backgroundColor: "complementary.destructiveSecondary"
      }
    }
  ]
});
var FeedbackButton = React16.forwardRef(
  ({ type, isActive, label, onClick }, ref) => {
    const [isHover, setIsHover] = useState(false);
    const positiveIcon = isHover || isActive ? faThumbsUpSolid : faThumbsUp;
    const negativeIcon = isHover || isActive ? faThumbsDownSolid : faThumbsDown;
    return /* @__PURE__ */ jsx23(Tooltip, { content: label, children: /* @__PURE__ */ jsx23(
      Button,
      {
        "aria-label": label,
        className: feedBackButtonRecipe({ type, isActive }),
        iconOnly: type === "positive" ? positiveIcon : negativeIcon,
        onClick,
        onMouseEnter: () => setIsHover(true),
        onMouseLeave: () => setIsHover(false),
        ref
      }
    ) });
  }
);
FeedbackButton.displayName = "FeedbackButton";

// src/FollowUpCard/FollowUpCard.tsx
import { css as css15, cx as cx10 } from "@doowii/design-system/css";
import { flex } from "@doowii/design-system/patterns";
import { faPaperPlaneTop } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon10 } from "@fortawesome/react-fontawesome";
import { jsx as jsx24, jsxs as jsxs13 } from "react/jsx-runtime";
var followUpCardStyle = cx10(
  flex({
    gap: "ld",
    direction: "row",
    align: "center",
    justify: "center"
  }),
  css15({
    bg: "base.blueBrandTertiary",
    p: "md",
    rounded: "xl",
    cursor: "pointer",
    w: "full",
    textAlign: "left",
    color: "base.darkBlue",
    _hover: {
      bg: "base.white"
    }
  })
);
var followUpCardTextStyle = cx10(
  flex({
    grow: 1
  }),
  css15({
    textStyle: "paragraph_16"
  })
);
var followUpCardIconStyle = cx10(
  flex({
    align: "center",
    justify: "center",
    shrink: 0
  }),
  css15({
    w: "32px",
    h: "32px"
  })
);
var FollowUpCard = ({ onClick, children }) => /* @__PURE__ */ jsxs13("button", { className: followUpCardStyle, onClick, type: "button", children: [
  /* @__PURE__ */ jsx24("div", { className: followUpCardTextStyle, children }),
  /* @__PURE__ */ jsx24("div", { className: followUpCardIconStyle, children: /* @__PURE__ */ jsx24(FontAwesomeIcon10, { icon: faPaperPlaneTop }) })
] });

// src/InputField/InputField.tsx
import { css as css16, cva as cva9 } from "@doowii/design-system/css";
import { Box as Box6, Flex as Flex3 } from "@doowii/design-system/jsx";
import { forwardRef as forwardRef11, useId, useMemo, useState as useState2 } from "react";
import { jsx as jsx25, jsxs as jsxs14 } from "react/jsx-runtime";
var DEFAULT_INPUT_SIZE = 20;
var inputFieldRecipe = cva9({
  base: {
    color: "foreground.base",
    outline: "none",
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "border.base",
    fontWeight: 500
  },
  variants: {
    size: {
      small: {
        padding: "sm",
        paddingRight: "xl3",
        textStyle: "paragraph_14",
        borderRadius: "sm"
      },
      medium: {
        padding: "md",
        paddingRight: "xl3",
        textStyle: "paragraph_16",
        borderRadius: "md"
      },
      large: {
        padding: "ld",
        paddingRight: "xl3",
        textStyle: "paragraph_18",
        borderRadius: "lg"
      }
    },
    disabled: {
      true: {
        backgroundColor: "background.disabled",
        borderColor: "border.disabled"
      }
    },
    error: {
      true: {
        borderColor: "border.error",
        "&:focus": {
          borderColor: "border.error"
        }
      }
    }
  },
  defaultVariants: {
    size: "medium"
  }
});
var InputField = forwardRef11(
  ({
    label,
    value,
    size,
    defaultValue,
    onChange,
    helperText,
    errorText,
    disabled,
    icon,
    inputSize,
    id: propId,
    ...props
  }, ref) => {
    const [internalValue, setInternalValue] = useState2(defaultValue ?? "");
    const id = useId();
    const inputId = propId ?? `input-${id}`;
    const handleChange = (e) => {
      onChange?.(e);
      if (value === void 0) {
        setInternalValue(e.target.value);
      }
    };
    const ariaDescribedBy = useMemo(() => {
      const describedBy = [];
      if (errorText) {
        describedBy.push(`${inputId}-error`);
      }
      if (helperText) {
        describedBy.push(`${inputId}-helper`);
      }
      return describedBy.join(" ");
    }, [errorText, helperText, inputId]);
    return /* @__PURE__ */ jsxs14(Flex3, { direction: "column", gap: "sm", children: [
      label.length > 0 ? /* @__PURE__ */ jsx25(Label, { htmlFor: inputId, level: 1, children: label }) : null,
      /* @__PURE__ */ jsxs14(
        Box6,
        {
          className: css16({
            display: "flex",
            alignItems: "center",
            position: "relative"
          }),
          children: [
            /* @__PURE__ */ jsx25(
              "input",
              {
                "aria-describedby": ariaDescribedBy || void 0,
                "aria-invalid": Boolean(errorText),
                className: inputFieldRecipe({
                  size,
                  disabled,
                  error: Boolean(errorText)
                }),
                disabled,
                id: inputId,
                onChange: handleChange,
                ref,
                value: value ?? internalValue,
                ...props,
                size: inputSize ?? DEFAULT_INPUT_SIZE
              }
            ),
            icon ? /* @__PURE__ */ jsx25(
              Box6,
              {
                className: css16({
                  position: "absolute",
                  right: "md",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }),
                children: icon
              }
            ) : null,
            errorText ? /* @__PURE__ */ jsx25(
              "p",
              {
                className: css16({
                  position: "absolute",
                  bottom: "-ld",
                  margin: 0,
                  textStyle: "paragraph_12",
                  color: "foreground.error"
                }),
                id: `${inputId}-error`,
                children: errorText
              }
            ) : null,
            helperText ? /* @__PURE__ */ jsx25(
              "p",
              {
                className: css16({
                  position: "absolute",
                  bottom: errorText ? "-xl2" : "-ld",
                  margin: 0,
                  textStyle: "paragraph_12"
                }),
                id: `${inputId}-helper`,
                children: helperText
              }
            ) : null
          ]
        }
      )
    ] });
  }
);
InputField.displayName = "InputField";

// src/Loading/LoadingLogo.tsx
import { css as css17, cx as cx11 } from "@doowii/design-system/css";
import { jsx as jsx26, jsxs as jsxs15 } from "react/jsx-runtime";
var loadingPageStyles = css17({
  fontSize: "5em",
  textAlign: "center",
  fontWeight: 600,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1
});
var bounceStyles = css17({
  display: "inline-block",
  animation: "bounce 0.6s infinite alternate"
});
var LoadingLogo = () => /* @__PURE__ */ jsx26("div", { className: loadingPageStyles, children: /* @__PURE__ */ jsxs15(
  "svg",
  {
    fill: "none",
    height: "120",
    viewBox: "0 0 334 120",
    width: "334",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx26(
        "path",
        {
          className: cx11(bounceStyles),
          d: "M300.167 26.457C303.339 26.457 305.819 28.8014 305.819 31.8337C305.819 34.8659 303.337 37.1435 300.167 37.1435C296.996 37.1435 294.652 34.8682 294.652 31.8337C294.652 28.7991 296.996 26.457 300.167 26.457Z",
          fill: "#F5CB62",
          style: { animationDelay: "0.23s" }
        }
      ),
      /* @__PURE__ */ jsx26(
        "path",
        {
          className: cx11(bounceStyles),
          d: "M275.454 26.457C278.627 26.457 281.107 28.8014 281.107 31.8337C281.107 34.8659 278.625 37.1435 275.454 37.1435C272.284 37.1435 269.94 34.8682 269.94 31.8337C269.94 28.7991 272.284 26.457 275.454 26.457Z",
          fill: "#F5CB62",
          style: { animationDelay: "0.2s" }
        }
      ),
      /* @__PURE__ */ jsx26(
        "path",
        {
          className: bounceStyles,
          d: "M127.61 49.0186C123.012 44.7106 116.832 42.2305 110.456 42.2305C103.349 42.2305 96.993 44.8464 92.4156 49.2457C87.8382 53.6427 85.0442 59.8275 85.0464 66.9028C85.0642 80.0405 96.6879 91.1522 109.801 91.1655C124.624 91.1655 135.043 79.5195 135.21 67.0743V66.7692C135.215 59.478 132.207 53.3243 127.61 49.0186ZM99.3596 55.7644C102.156 52.9681 105.97 51.2405 110.046 51.2427C118.626 51.2427 125.459 58.082 125.461 66.5756C125.457 75.3963 118.468 82.1466 109.883 82.1533C105.774 82.1533 102.004 80.3522 99.2549 77.5069C96.5076 74.6616 94.7956 70.7811 94.7978 66.6579C94.7978 62.4145 96.5655 58.5629 99.3618 55.7666L99.3596 55.7644Z",
          fill: "#053946",
          style: { animationDelay: "0s" }
        }
      ),
      /* @__PURE__ */ jsx26(
        "path",
        {
          d: "M78.3027 27.7148H68.5535V47.28C64.2633 44.0718 58.9914 42.2306 53.5479 42.2306C46.4415 42.2306 40.0852 44.8466 35.5079 49.2458C30.9305 53.6429 28.1364 59.8276 28.1387 66.903C28.1565 80.0406 39.7802 91.1523 52.8934 91.1657C66.9505 91.1657 77.0314 80.6885 78.178 68.9913L78.3027 67.0388C78.3027 67.0388 78.3027 67.0366 78.3027 67.0343V27.7148ZM42.4518 55.7646C45.2481 52.9683 49.0619 51.2406 53.1383 51.2429C61.7186 51.2429 68.5513 58.0822 68.5535 66.5757C68.5491 75.3965 61.5606 82.1468 52.9758 82.1534C48.8659 82.1534 45.0967 80.3523 42.3472 77.5071C39.5999 74.6618 37.8878 70.7813 37.8901 66.6581C37.8901 62.4147 39.6578 58.5631 42.4541 55.7668L42.4518 55.7646Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx26(
        "path",
        {
          className: bounceStyles,
          d: "M182.827 49.0186C178.23 44.7106 172.05 42.2305 165.673 42.2305C158.567 42.2305 152.211 44.8464 147.633 49.2457C143.056 53.6427 140.262 59.8275 140.264 66.9028C140.282 80.0405 151.906 91.1522 165.019 91.1655C179.842 91.1655 190.261 79.5195 190.428 67.0743V66.7692C190.433 59.478 187.425 53.3243 182.827 49.0186ZM154.577 55.7644C157.374 52.9681 161.187 51.2405 165.264 51.2427C173.844 51.2427 180.677 58.082 180.679 66.5756C180.675 75.3963 173.686 82.1466 165.101 82.1533C160.991 82.1533 157.222 80.3522 154.473 77.5069C151.725 74.6616 150.013 70.7811 150.016 66.6579C150.016 62.4145 151.783 58.5629 154.58 55.7666L154.577 55.7644Z",
          fill: "#053946",
          style: { animationDelay: "0.1s" }
        }
      ),
      /* @__PURE__ */ jsx26(
        "path",
        {
          d: "M280.403 43.4961H279.782H270.654V90.7992H280.403V43.4961Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx26(
        "path",
        {
          d: "M305.561 43.4961H304.94H295.812V90.7992H305.561V43.4961Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx26(
        "path",
        {
          d: "M252.316 43.3242H251.697L240.528 74.9962L232.172 43.3242H232.059H231.437H223.042H222.423H222.309L213.954 74.9962L202.784 43.3242H202.163H193.035L209.715 90.6274H209.828H219.464H219.578L227.241 61.5869L234.902 90.6274H235.015H244.651H244.764L261.446 43.3242H252.316Z",
          fill: "#053946"
        }
      )
    ]
  }
) });

// src/Logo/Logo.tsx
import { jsx as jsx27, jsxs as jsxs16 } from "react/jsx-runtime";
var LogoDoowii = () => /* @__PURE__ */ jsxs16(
  "svg",
  {
    fill: "none",
    height: "48",
    viewBox: "0 0 134 48",
    width: "134",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M120.067 10.582C121.336 10.582 122.328 11.5198 122.328 12.7327C122.328 13.9456 121.335 14.8566 120.067 14.8566C118.799 14.8566 117.861 13.9465 117.861 12.7327C117.861 11.5189 118.799 10.582 120.067 10.582Z",
          fill: "#F5CB62"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M110.182 10.582C111.451 10.582 112.443 11.5198 112.443 12.7327C112.443 13.9456 111.45 14.8566 110.182 14.8566C108.914 14.8566 107.976 13.9465 107.976 12.7327C107.976 11.5189 108.914 10.582 110.182 10.582Z",
          fill: "#F5CB62"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M51.0439 19.6059C49.2049 17.8827 46.7328 16.8906 44.1823 16.8906C41.3397 16.8906 38.7972 17.937 36.9662 19.6967C35.1353 21.4555 34.0177 23.9294 34.0186 26.7596C34.0257 32.0146 38.6752 36.4593 43.9204 36.4646C49.8497 36.4646 54.0174 31.8062 54.0842 26.8281V26.7061C54.0859 23.7896 52.8828 21.3282 51.0439 19.6059ZM39.7438 22.3042C40.8623 21.1857 42.3878 20.4946 44.0184 20.4955C47.4505 20.4955 50.1836 23.2313 50.1845 26.6287C50.1827 30.157 47.3873 32.8571 43.9534 32.8598C42.3095 32.8598 40.8018 32.1393 39.702 31.0012C38.603 29.8631 37.9182 28.3109 37.9191 26.6616C37.9191 24.9642 38.6262 23.4236 39.7447 22.3051L39.7438 22.3042Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M31.3212 11.0859H27.4216V18.912C25.7055 17.6287 23.5967 16.8922 21.4193 16.8922C18.5767 16.8922 16.0342 17.9386 14.2033 19.6983C12.3724 21.4571 11.2547 23.9311 11.2556 26.7612C11.2627 32.0163 15.9122 36.4609 21.1575 36.4663C26.7804 36.4663 30.8127 32.2754 31.2713 27.5965L31.3212 26.8155C31.3212 26.8155 31.3212 26.8146 31.3212 26.8137V11.0859ZM16.9809 22.3058C18.0994 21.1873 19.6249 20.4963 21.2555 20.4971C24.6876 20.4971 27.4207 23.2329 27.4216 26.6303C27.4198 30.1586 24.6244 32.8587 21.1905 32.8614C19.5465 32.8614 18.0388 32.1409 16.939 31.0028C15.8401 29.8647 15.1553 28.3125 15.1562 26.6632C15.1562 24.9659 15.8633 23.4252 16.9818 22.3067L16.9809 22.3058Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M73.131 19.6059C71.2921 17.8827 68.8199 16.8906 66.2694 16.8906C63.4268 16.8906 60.8843 17.937 59.0534 19.6967C57.2224 21.4555 56.1048 23.9294 56.1057 26.7596C56.1128 32.0146 60.7623 36.4593 66.0076 36.4646C71.9368 36.4646 76.1045 31.8062 76.1713 26.8281V26.7061C76.1731 23.7896 74.97 21.3282 73.131 19.6059ZM61.831 22.3042C62.9495 21.1857 64.475 20.4946 66.1056 20.4955C69.5377 20.4955 72.2708 23.2313 72.2716 26.6287C72.2699 30.157 69.4745 32.8571 66.0406 32.8598C64.3966 32.8598 62.8889 32.1393 61.7891 31.0012C60.6902 29.8631 60.0054 28.3109 60.0063 26.6616C60.0063 24.9642 60.7134 23.4236 61.8319 22.3051L61.831 22.3042Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M112.162 17.3984H111.913H108.262V36.3197H112.162V17.3984Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M122.225 17.3984H121.976H118.325V36.3197H122.225V17.3984Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M100.926 17.3281H100.679L96.2111 29.9969L92.8689 17.3281H92.8235H92.575H89.2168H88.9692H88.9238L85.5816 29.9969L81.1138 17.3281H80.8653H77.2141L83.886 36.2494H83.9314H87.7857H87.8311L90.8963 24.6332L93.9607 36.2494H94.0061H97.8603H97.9058L104.579 17.3281H100.926Z",
          fill: "#053946"
        }
      )
    ]
  }
);
var LogoD = () => /* @__PURE__ */ jsxs16(
  "svg",
  {
    fill: "none",
    height: "48",
    viewBox: "0 0 48 48",
    width: "48",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M38.3365 10.8555L38.3186 10.8715L38.3365 10.8555Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M5.3522 26.0644C5.64212 25.8807 5.97012 25.7508 6.32116 25.6887C6.59956 25.6394 6.89268 25.6359 7.18644 25.6798C6.97108 23.7322 7.0914 21.7153 7.59828 19.7066C9.87764 10.6711 19.0834 5.17448 28.1189 7.45416C30.5759 8.074 32.7682 9.20904 34.6165 10.7102L34.8028 10.4116C35.1247 9.89448 35.5999 9.5204 36.1231 9.3204C36.2872 9.25736 36.4572 9.21704 36.629 9.18696C34.4191 7.27688 31.7448 5.83624 28.7196 5.07304C18.3548 2.45832 7.83252 8.74088 5.21748 19.1057C4.5874 21.6039 4.4786 24.1098 4.8146 26.5134C4.97396 26.3418 5.1538 26.1898 5.3522 26.0641V26.0644Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M41.8553 16.4023C41.5456 16.9451 41.0835 17.7534 40.6067 18.5854C40.4829 18.8014 40.3587 19.0177 40.2358 19.2318C41.0243 21.995 41.1232 24.9927 40.3712 27.9739C38.0918 37.0094 28.8861 42.506 19.8506 40.2263C16.4074 39.3579 13.4806 37.4814 11.2998 34.994L7.58911 34.1163C10.1453 38.1959 14.2134 41.3364 19.2499 42.6071C29.6147 45.2219 40.1369 38.9393 42.752 28.5745C43.8144 24.3627 43.408 20.1249 41.855 16.4023H41.8553Z",
          fill: "#053946"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M39.1108 10.043L39.1224 10.0561L39.1227 10.0564L39.1108 10.043Z",
          fill: "white"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M7.21009 32.8547L15.2779 34.7629C15.4626 34.7747 15.6431 34.7037 15.7701 34.5689L16.9125 33.3549C17.0491 33.2099 17.2786 33.2067 17.4191 33.3478L20.0904 36.0307C20.2917 36.2329 20.6264 36.2048 20.7912 35.9721L24.4507 30.8073L24.4555 30.8105L29.2568 30.5923C29.4648 30.5827 29.6533 30.4678 29.757 30.2873L35.2258 20.7619C35.3775 20.4979 35.5282 20.3398 35.8133 20.4477L37.6107 21.1657C37.6955 21.2009 37.7931 21.1699 37.8405 21.0912C38.2786 20.3651 40.2498 16.9155 40.9749 15.6451C41.1432 15.3501 41.1531 14.9913 41.0018 14.687C40.7986 14.2784 40.221 14.2627 39.9963 14.6601C39.1707 16.1213 37.6293 18.8448 37.2587 19.463C37.2136 19.5382 37.1221 19.5712 37.0392 19.5427L35.38 18.9737C35.0677 18.8614 34.7787 18.9814 34.6264 19.2429L28.9064 29.0675C28.7992 29.2518 28.6031 29.3667 28.3896 29.3699L24.1202 29.4345C23.9256 29.4374 23.7442 29.5334 23.6319 29.6925L20.549 34.0662C20.4306 34.2342 20.1899 34.256 20.0434 34.1123L17.4213 31.5433C17.2591 31.3843 16.9979 31.3881 16.8405 31.5523L15.2242 33.2374C15.0968 33.3702 14.917 33.4397 14.7333 33.4269L7.23025 31.6777C6.80625 31.6483 6.61105 31.9984 6.62577 32.303C6.64241 32.6505 6.94353 32.8384 7.20977 32.8557L7.21009 32.8547Z",
          fill: "#F5CB62"
        }
      ),
      /* @__PURE__ */ jsx27(
        "path",
        {
          d: "M38.3186 10.8706L38.2668 10.8114C38.0485 10.5631 37.7717 10.4024 37.4796 10.3288C37.1653 10.2495 36.8332 10.27 36.5317 10.3858C36.2303 10.5013 35.9586 10.7141 35.7705 11.0159L34.8028 12.5656C34.5157 13.0252 33.965 13.2469 33.4396 13.1144L32.9893 13.0008C32.2981 12.8264 31.5769 13.1423 31.2361 13.7682L26.6156 22.2568C26.3353 22.7724 25.7493 23.005 25.2149 22.8706C25.077 22.8357 24.9426 22.7765 24.8172 22.6911L22.6933 21.2428C22.5388 21.1375 22.3701 21.0626 22.1957 21.0188C21.7385 20.9032 21.2421 20.9989 20.8543 21.3007L12.6841 27.661C12.3858 27.893 11.9986 27.9775 11.6309 27.8908L7.15094 26.8322C6.71766 26.7295 6.29558 26.8175 5.96118 27.029C5.62646 27.2405 5.3759 27.5752 5.27638 27.9698C5.22326 28.18 5.21558 28.4072 5.25942 28.6389L5.25814 28.644L5.26582 28.6799C5.39094 29.261 5.83382 29.7199 6.40918 29.8652L6.42966 29.8703C7.97654 30.2383 11.1932 31.0005 12.5925 31.309C13.0533 31.4104 13.5349 31.2984 13.9036 31.004L21.1077 25.2463C21.4191 24.9973 21.8217 24.918 22.1919 25.0111C22.3349 25.0472 22.4732 25.109 22.5999 25.1973L25.6716 27.3391C25.8313 27.4504 26.0047 27.5279 26.1826 27.5727C26.8549 27.7426 27.5913 27.4469 27.9407 26.796L32.9852 17.3861C33.2418 16.9071 33.7794 16.6495 34.3135 16.7493L34.8687 16.853C35.5173 16.9743 36.1721 16.6744 36.5045 16.1042L38.4949 12.6882C38.5698 12.5596 38.6249 12.4236 38.6597 12.284C38.7833 11.7941 38.6636 11.2636 38.318 10.8709L38.3186 10.8706Z",
          fill: "#2D7E9B"
        }
      )
    ]
  }
);
var Logo = ({ full = true, className }) => /* @__PURE__ */ jsx27("div", { className, children: full ? /* @__PURE__ */ jsx27(LogoDoowii, {}) : /* @__PURE__ */ jsx27(LogoD, {}) });

// src/LogoLoader/LogoLoader.tsx
import { useEffect, useRef } from "react";
import { css as css18, cx as cx12 } from "@doowii/design-system/css";
import { Fragment as Fragment2, jsx as jsx28, jsxs as jsxs17 } from "react/jsx-runtime";
var containerStyles = css18({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%"
});
var sizeStyles = {
  sm: css18({
    width: "100px",
    height: "100px"
  }),
  md: css18({
    width: "200px",
    height: "200px"
  }),
  lg: css18({
    width: "300px",
    height: "300px"
  })
};
var svgStyles = css18({
  width: "100%",
  height: "100%"
});
var lineStyles = css18({
  fill: "none",
  strokeWidth: "25",
  strokeLinecap: "round"
});
var line1Styles = css18({
  stroke: "#2d7e9a",
  animation: `growLine 3s infinite forwards ease-in-out 0.5s`
});
var line2Styles = css18({
  stroke: "#f4cb61",
  animation: `growLine 3s infinite forwards ease-in-out 0.5s`
});
var LogoLoader = ({ size = "md", variant, progress = 0 }) => {
  const topRectRef = useRef(null);
  const bottomRectRef = useRef(null);
  const line1Ref = useRef(null);
  const line2Ref = useRef(null);
  useEffect(() => {
    if (variant === "progress" && topRectRef.current && bottomRectRef.current) {
      const topRect = topRectRef.current;
      const bottomRect = bottomRectRef.current;
      if (progress <= 50) {
        const topWidth = progress / 50 * 5250;
        topRect.setAttribute("width", topWidth.toString());
        bottomRect.setAttribute("width", "0");
        bottomRect.setAttribute("x", "5250");
      } else {
        topRect.setAttribute("width", "5250");
        const bottomWidth = (progress - 50) / 50 * 5250;
        bottomRect.setAttribute("width", bottomWidth.toString());
        bottomRect.setAttribute("x", (5250 - bottomWidth).toString());
      }
    }
  }, [variant, progress]);
  useEffect(() => {
    if (line1Ref.current && line2Ref.current) {
      const line1 = line1Ref.current;
      const line2 = line2Ref.current;
      const length1 = line1.getTotalLength();
      const length2 = line2.getTotalLength();
      line1.style.strokeDasharray = `${length1}`;
      line1.style.strokeDashoffset = `${length1}`;
      line2.style.strokeDasharray = `${length2}`;
      line2.style.strokeDashoffset = `${length2}`;
    }
  }, [variant]);
  const sizeClass = sizeStyles[size];
  return /* @__PURE__ */ jsx28("div", { className: cx12(containerStyles, sizeClass), children: /* @__PURE__ */ jsx28("svg", { viewBox: "0 0 5250 5250", className: svgStyles, children: variant === "progress" ? /* @__PURE__ */ jsxs17(Fragment2, { children: [
    /* @__PURE__ */ jsxs17("defs", { children: [
      /* @__PURE__ */ jsx28("mask", { id: "topMask", children: /* @__PURE__ */ jsx28("rect", { x: "0", y: "0", width: "0", height: "5250", fill: "white", ref: topRectRef }) }),
      /* @__PURE__ */ jsx28("mask", { id: "bottomMask", children: /* @__PURE__ */ jsx28(
        "rect",
        {
          x: "5250",
          y: "0",
          width: "0",
          height: "5250",
          fill: "white",
          ref: bottomRectRef
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx28("g", { children: /* @__PURE__ */ jsx28(
      "path",
      {
        mask: "url(#topMask)",
        style: { opacity: 0.997 },
        fill: "#053946",
        d: "M 2460.5,-0.5 C 2499.17,-0.5 2537.83,-0.5 2576.5,-0.5C 3031.49,12.7921 3453.16,137.292 3841.5,373C 3956.78,443.809 4065.45,523.643 4167.5,612.5C 4072.41,630.599 3996.58,678.599 3940,756.5C 3928.33,774.99 3916.5,793.324 3904.5,811.5C 3581.05,551.055 3212.05,393.222 2797.5,338C 2340.56,283.265 1907.89,361.599 1499.5,573C 1172.11,746.028 903.942,985.861 695,1292.5C 464.494,1639.04 340.16,2021.04 322,2438.5C 318.135,2546.11 321.968,2653.45 333.5,2760.5C 211.938,2745.46 108.938,2781.79 24.5,2869.5C 9.93922,2767.87 1.60589,2665.87 -0.5,2563.5C -0.5,2534.5 -0.5,2505.5 -0.5,2476.5C 9.89759,2033.54 126.398,1620.87 349,1238.5C 579.765,852.067 890.598,547.567 1281.5,325C 1647.65,120.041 2040.65,11.541 2460.5,-0.5 Z"
      }
    ) }),
    /* @__PURE__ */ jsx28("g", { children: /* @__PURE__ */ jsx28(
      "path",
      {
        ref: line1Ref,
        className: cx12(lineStyles, line1Styles),
        d: "M 189 3294 C 127 3274 103.249 3230.75 81 3160 C 67.9277 3071.04 91.4277 3003.21 153.5 2949 C 199.771 2913.01 252.104 2898.68 310.5 2906 C 513.901 2953.35 717.234 3001.02 920.5 3049 C 970.095 3056.85 1014.43 3045.19 1053.5 3014 C 1406.5 2739 1759.5 2464 2112.5 2189 C 2177.47 2142.53 2247.14 2134.2 2321.5 2164 C 2335.95 2170.8 2349.61 2178.8 2362.5 2188 C 2453.17 2250 2543.83 2312 2634.5 2374 C 2689.6 2405.53 2745.26 2406.53 2801.5 2377 C 2824.95 2362.55 2844.12 2343.71 2859 2320.5 C 3060.67 1949.83 3262.33 1579.17 3464 1208.5 C 3510.28 1131.77 3578.44 1096.94 3668.5 1104 C 3698.71 1110.05 3728.71 1117.05 3758.5 1125 C 3824.64 1135.63 3878.81 1115.13 3921 1063.5 C 3966.4 991.766 4011.4 919.766 4056 847.5 C 4100.86 782.49 4163.02 751.657 4242.5 755 C 4301.39 759.939 4349.39 785.105 4392 829 C 4438.16 893.667 4450.33 962.667 4425 1038.5 C 4420.13 1050.56 4414.47 1062.23 4408 1073.5 C 4321.33 1222.17 4234.67 1370.83 4148 1519.5 C 4113.35 1572.44 4064.51 1603.6 4001.5 1613 C 3987.19 1614.46 3972.86 1614.8 3958.5 1614 C 3923.75 1608.52 3889.08 1602.52 3854.5 1596 C 3785.02 1591.15 3731.86 1617.99 3695 1676.5 C 3475.33 2086.5 3255.67 2496.5 3036 2906.5 C 2995.93 2974.63 2936.09 3010.3 2856.5 3013.5 C 2813.26 3012.92 2773.92 3000.42 2738.5 2976 C 2603.01 2881.09 2467.34 2786.42 2331.5 2692 C 2273.24 2659.82 2215.57 2660.82 2158.5 2695 C 1841.54 2947.62 1524.88 3200.62 1208.5 3454 C 1156.57 3493.39 1098.57 3506.39 1034.5 3493 C 765.841 3431.75 497.508 3369.09 229.5 3305 Z"
      }
    ) }),
    /* @__PURE__ */ jsx28("g", { children: /* @__PURE__ */ jsx28(
      "path",
      {
        ref: line2Ref,
        className: cx12(lineStyles, line2Styles),
        d: "M 275 3680 C 267.02 3668.2 252.52 3636.7 263 3593.5 C 275.395 3556.22 301.229 3538.72 340.5 3541 C 665.167 3616.67 989.833 3692.33 1314.5 3768 C 1337.71 3769.93 1358.05 3763.26 1375.5 3748 C 1448.47 3671.36 1521.8 3595.03 1595.5 3519 C 1618.91 3503.59 1641.91 3504.26 1664.5 3521 C 1779.86 3634.7 1895.53 3748.03 2011.5 3861 C 2032.35 3874.41 2051.85 3872.57 2070 3855.5 C 2204.33 3664.83 2338.67 3474.17 2473 3283.5 C 2486.91 3263.45 2506.07 3251.95 2530.5 3249 C 2719.83 3245.9 2909.16 3242.9 3098.5 3240 C 3126.45 3236.95 3147.62 3223.45 3162 3199.5 C 3411.58 2769.34 3661.91 2339.67 3913 1910.5 C 3938.78 1881.93 3969.95 1874.1 4006.5 1887 C 4078.09 1911.86 4149.76 1936.53 4221.5 1961 C 4232.36 1963.38 4241.2 1960.21 4248 1951.5 C 4369.01 1741.48 4489.01 1530.82 4608 1319.5 C 4620.57 1302.1 4637.4 1291.43 4659 1287 C 4701.99 1284.84 4730.49 1304.17 4744 1345.5 C 4755.14 1382.06 4752.14 1417.4 4735 1451.5 C 4599.01 1690.15 4462.01 1928.15 4324 2165.5 C 4316.93 2173.61 4308.1 2176.44 4297.5 2174 C 4215.95 2141.39 4134.28 2109.06 4052.5 2077 C 4031.92 2072.08 4015.09 2077.91 4002 2094.5 C 3995.59 2102.31 3989.92 2110.65 3985 2119.5 C 3746.67 2534.83 3508.33 2950.17 3270 3365.5 C 3256.29 3385.18 3237.46 3396.68 3213.5 3400 C 3002.84 3409.73 2792.17 3419.39 2581.5 3429 C 2421.54 3654.41 2261.71 3879.91 2102 4105.5 C 2083.4 4124.69 2061.24 4130.52 2035.5 4123 C 2029.01 4120.5 2023.01 4117.17 2017.5 4113 C 1898.86 3993.36 1779.86 3874.03 1660.5 3755 C 1642.17 3742.33 1623.83 3742.33 1605.5 3755 C 1551.87 3812.3 1497.87 3869.3 1443.5 3926 C 1426.11 3939.35 1406.44 3945.02 1384.5 3943 C 1025.08 3858.12 665.75 3773.12 306.5 3688 Z"
      }
    ) }),
    /* @__PURE__ */ jsx28("g", { children: /* @__PURE__ */ jsx28(
      "path",
      {
        mask: "url(#bottomMask)",
        style: { opacity: 0.997 },
        fill: "#053946",
        d: "M 5041.5,2452.5 C 5041.5,2496.83 5041.5,2541.17 5041.5,2585.5C 5027.32,3019.59 4911.49,3424.25 4694,3799.5C 4485.3,4150.87 4208.13,4436.04 3862.5,4655C 3474.38,4896.53 3051.38,5025.36 2593.5,5041.5C 2546.17,5041.5 2498.83,5041.5 2451.5,5041.5C 2024.56,5026.94 1625.89,4913.77 1255.5,4702C 897.248,4491.92 607.248,4211.09 385.5,3859.5C 386.167,3859.17 386.833,3858.83 387.5,3858.5C 548.26,3896.36 708.927,3934.52 869.5,3973C 1158.9,4300.15 1514.9,4523.15 1937.5,4642C 2295,4738.86 2654.34,4746.2 3015.5,4664C 3412.69,4568.64 3757.69,4380.97 4050.5,4101C 4366.21,3793.25 4572.71,3422.75 4670,2989.5C 4746.9,2631.76 4735.9,2276.76 4637,1924.5C 4636.33,1922.5 4636.33,1920.5 4637,1918.5C 4707.17,1796.17 4777.33,1673.83 4847.5,1551.5C 4967.64,1839.88 5032.31,2140.21 5041.5,2452.5 Z"
      }
    ) })
  ] }) : /* @__PURE__ */ jsxs17(Fragment2, { children: [
    /* @__PURE__ */ jsx28("g", { children: /* @__PURE__ */ jsx28(
      "path",
      {
        style: { opacity: 0.997 },
        fill: "#053946",
        d: "M 2460.5,-0.5 C 2499.17,-0.5 2537.83,-0.5 2576.5,-0.5C 3031.49,12.7921 3453.16,137.292 3841.5,373C 3956.78,443.809 4065.45,523.643 4167.5,612.5C 4072.41,630.599 3996.58,678.599 3940,756.5C 3928.33,774.99 3916.5,793.324 3904.5,811.5C 3581.05,551.055 3212.05,393.222 2797.5,338C 2340.56,283.265 1907.89,361.599 1499.5,573C 1172.11,746.028 903.942,985.861 695,1292.5C 464.494,1639.04 340.16,2021.04 322,2438.5C 318.135,2546.11 321.968,2653.45 333.5,2760.5C 211.938,2745.46 108.938,2781.79 24.5,2869.5C 9.93922,2767.87 1.60589,2665.87 -0.5,2563.5C -0.5,2534.5 -0.5,2505.5 -0.5,2476.5C 9.89759,2033.54 126.398,1620.87 349,1238.5C 579.765,852.067 890.598,547.567 1281.5,325C 1647.65,120.041 2040.65,11.541 2460.5,-0.5 Z"
      }
    ) }),
    /* @__PURE__ */ jsx28("g", { children: /* @__PURE__ */ jsx28(
      "path",
      {
        ref: line1Ref,
        className: cx12(lineStyles, line1Styles),
        d: "M 189 3294 C 127 3274 103.249 3230.75 81 3160 C 67.9277 3071.04 91.4277 3003.21 153.5 2949 C 199.771 2913.01 252.104 2898.68 310.5 2906 C 513.901 2953.35 717.234 3001.02 920.5 3049 C 970.095 3056.85 1014.43 3045.19 1053.5 3014 C 1406.5 2739 1759.5 2464 2112.5 2189 C 2177.47 2142.53 2247.14 2134.2 2321.5 2164 C 2335.95 2170.8 2349.61 2178.8 2362.5 2188 C 2453.17 2250 2543.83 2312 2634.5 2374 C 2689.6 2405.53 2745.26 2406.53 2801.5 2377 C 2824.95 2362.55 2844.12 2343.71 2859 2320.5 C 3060.67 1949.83 3262.33 1579.17 3464 1208.5 C 3510.28 1131.77 3578.44 1096.94 3668.5 1104 C 3698.71 1110.05 3728.71 1117.05 3758.5 1125 C 3824.64 1135.63 3878.81 1115.13 3921 1063.5 C 3966.4 991.766 4011.4 919.766 4056 847.5 C 4100.86 782.49 4163.02 751.657 4242.5 755 C 4301.39 759.939 4349.39 785.105 4392 829 C 4438.16 893.667 4450.33 962.667 4425 1038.5 C 4420.13 1050.56 4414.47 1062.23 4408 1073.5 C 4321.33 1222.17 4234.67 1370.83 4148 1519.5 C 4113.35 1572.44 4064.51 1603.6 4001.5 1613 C 3987.19 1614.46 3972.86 1614.8 3958.5 1614 C 3923.75 1608.52 3889.08 1602.52 3854.5 1596 C 3785.02 1591.15 3731.86 1617.99 3695 1676.5 C 3475.33 2086.5 3255.67 2496.5 3036 2906.5 C 2995.93 2974.63 2936.09 3010.3 2856.5 3013.5 C 2813.26 3012.92 2773.92 3000.42 2738.5 2976 C 2603.01 2881.09 2467.34 2786.42 2331.5 2692 C 2273.24 2659.82 2215.57 2660.82 2158.5 2695 C 1841.54 2947.62 1524.88 3200.62 1208.5 3454 C 1156.57 3493.39 1098.57 3506.39 1034.5 3493 C 765.841 3431.75 497.508 3369.09 229.5 3305 Z"
      }
    ) }),
    /* @__PURE__ */ jsx28("g", { children: /* @__PURE__ */ jsx28(
      "path",
      {
        ref: line2Ref,
        className: cx12(lineStyles, line2Styles),
        d: "M 275 3680 C 267.02 3668.2 252.52 3636.7 263 3593.5 C 275.395 3556.22 301.229 3538.72 340.5 3541 C 665.167 3616.67 989.833 3692.33 1314.5 3768 C 1337.71 3769.93 1358.05 3763.26 1375.5 3748 C 1448.47 3671.36 1521.8 3595.03 1595.5 3519 C 1618.91 3503.59 1641.91 3504.26 1664.5 3521 C 1779.86 3634.7 1895.53 3748.03 2011.5 3861 C 2032.35 3874.41 2051.85 3872.57 2070 3855.5 C 2204.33 3664.83 2338.67 3474.17 2473 3283.5 C 2486.91 3263.45 2506.07 3251.95 2530.5 3249 C 2719.83 3245.9 2909.16 3242.9 3098.5 3240 C 3126.45 3236.95 3147.62 3223.45 3162 3199.5 C 3411.58 2769.34 3661.91 2339.67 3913 1910.5 C 3938.78 1881.93 3969.95 1874.1 4006.5 1887 C 4078.09 1911.86 4149.76 1936.53 4221.5 1961 C 4232.36 1963.38 4241.2 1960.21 4248 1951.5 C 4369.01 1741.48 4489.01 1530.82 4608 1319.5 C 4620.57 1302.1 4637.4 1291.43 4659 1287 C 4701.99 1284.84 4730.49 1304.17 4744 1345.5 C 4755.14 1382.06 4752.14 1417.4 4735 1451.5 C 4599.01 1690.15 4462.01 1928.15 4324 2165.5 C 4316.93 2173.61 4308.1 2176.44 4297.5 2174 C 4215.95 2141.39 4134.28 2109.06 4052.5 2077 C 4031.92 2072.08 4015.09 2077.91 4002 2094.5 C 3995.59 2102.31 3989.92 2110.65 3985 2119.5 C 3746.67 2534.83 3508.33 2950.17 3270 3365.5 C 3256.29 3385.18 3237.46 3396.68 3213.5 3400 C 3002.84 3409.73 2792.17 3419.39 2581.5 3429 C 2421.54 3654.41 2261.71 3879.91 2102 4105.5 C 2083.4 4124.69 2061.24 4130.52 2035.5 4123 C 2029.01 4120.5 2023.01 4117.17 2017.5 4113 C 1898.86 3993.36 1779.86 3874.03 1660.5 3755 C 1642.17 3742.33 1623.83 3742.33 1605.5 3755 C 1551.87 3812.3 1497.87 3869.3 1443.5 3926 C 1426.11 3939.35 1406.44 3945.02 1384.5 3943 C 1025.08 3858.12 665.75 3773.12 306.5 3688 Z"
      }
    ) }),
    /* @__PURE__ */ jsx28("g", { children: /* @__PURE__ */ jsx28(
      "path",
      {
        style: { opacity: 0.997 },
        fill: "#053946",
        d: "M 5041.5,2452.5 C 5041.5,2496.83 5041.5,2541.17 5041.5,2585.5C 5027.32,3019.59 4911.49,3424.25 4694,3799.5C 4485.3,4150.87 4208.13,4436.04 3862.5,4655C 3474.38,4896.53 3051.38,5025.36 2593.5,5041.5C 2546.17,5041.5 2498.83,5041.5 2451.5,5041.5C 2024.56,5026.94 1625.89,4913.77 1255.5,4702C 897.248,4491.92 607.248,4211.09 385.5,3859.5C 386.167,3859.17 386.833,3858.83 387.5,3858.5C 548.26,3896.36 708.927,3934.52 869.5,3973C 1158.9,4300.15 1514.9,4523.15 1937.5,4642C 2295,4738.86 2654.34,4746.2 3015.5,4664C 3412.69,4568.64 3757.69,4380.97 4050.5,4101C 4366.21,3793.25 4572.71,3422.75 4670,2989.5C 4746.9,2631.76 4735.9,2276.76 4637,1924.5C 4636.33,1922.5 4636.33,1920.5 4637,1918.5C 4707.17,1796.17 4777.33,1673.83 4847.5,1551.5C 4967.64,1839.88 5032.31,2140.21 5041.5,2452.5 Z"
      }
    ) })
  ] }) }) });
};

// src/MainSearchBar/MainSearchBar.tsx
import { css as css19, cx as cx13 } from "@doowii/design-system/css";
import { VisuallyHidden as VisuallyHidden2 } from "@doowii/design-system/jsx";
import { flex as flex2 } from "@doowii/design-system/patterns";
import {
  faMagnifyingGlass,
  faPaperPlaneTop as faPaperPlaneTop2
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon11 } from "@fortawesome/react-fontawesome";
import { useId as useId2, useState as useState3 } from "react";
import { jsx as jsx29, jsxs as jsxs18 } from "react/jsx-runtime";
var MainSearchBar = ({
  placeholder = "How can I help you today?",
  label: LabelText,
  onSubmit,
  size = "default"
}) => {
  const id = useId2();
  const [inputValue, setInputValue] = useState3("");
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit(inputValue);
      setInputValue("");
    }
  };
  const handleSubmitClick = () => {
    onSubmit(inputValue);
    setInputValue("");
  };
  const searchInputContainer = cx13(
    flex2({
      direction: "row",
      align: "center",
      gap: size === "small" ? "8px" : "16px",
      w: "full",
      maxW: size === "small" ? "100%" : "720px"
    }),
    css19({
      background: "base.white",
      rounded: "50px",
      position: "relative",
      textAlign: "center",
      paddingX: size === "small" ? "sm" : "ld",
      paddingY: size === "small" ? "sm" : "md",
      boxSizing: "border-box",
      boxShadow: "4px 8px 12px 0px #EFC42814, -4px -8px 12px 0px #2ABDF114",
      _before: {
        rounded: "50px",
        content: '""',
        backgroundImage: "linear-gradient(90deg, #29BDF0 47.66%, #F6CD3C 65.7%)",
        top: "-4px",
        left: "-4px",
        bottom: "-4px",
        right: "-4px",
        position: "absolute",
        zIndex: "-1"
      },
      _hover: {
        _before: {
          animation: "rotateBorder 3s ease-in-out infinite"
        },
        cursor: "pointer"
      }
    })
  );
  const iconStyle2 = css19({
    color: "base.darkBlue",
    fontSize: size === "small" ? "14px" : "24px"
  });
  const searchInput = css19({
    flexGrow: 1,
    fontSize: size === "small" ? "14px" : "24px",
    color: "base.gray",
    fontWeight: "medium",
    overflow: "hidden",
    _focus: {
      outline: "none",
      color: "base.darkBlue"
    }
  });
  return /* @__PURE__ */ jsxs18("div", { className: searchInputContainer, children: [
    /* @__PURE__ */ jsx29("div", { className: iconStyle2, children: /* @__PURE__ */ jsx29(FontAwesomeIcon11, { icon: faMagnifyingGlass }) }),
    /* @__PURE__ */ jsx29(VisuallyHidden2, { children: /* @__PURE__ */ jsx29("label", { htmlFor: `search-${id}`, children: LabelText }) }),
    /* @__PURE__ */ jsx29(
      "input",
      {
        className: searchInput,
        id: `search-${id}`,
        name: "chat input",
        onBlur: (e) => e.target.placeholder = placeholder,
        onChange: (e) => setInputValue(e.target.value),
        onFocus: (e) => e.target.placeholder = "",
        onKeyDown: handleKeyDown,
        placeholder,
        type: "text",
        value: inputValue
      }
    ),
    inputValue ? /* @__PURE__ */ jsx29(
      "button",
      {
        className: cx13(iconStyle2, css19({ cursor: "pointer" })),
        onClick: handleSubmitClick,
        onKeyDown: (e) => e.key === "Enter" && handleSubmitClick(),
        tabIndex: 0,
        type: "button",
        children: /* @__PURE__ */ jsx29(FontAwesomeIcon11, { icon: faPaperPlaneTop2 })
      }
    ) : null
  ] });
};

// src/NavBar/NavBar.tsx
import { css as css20, cva as cva10, cx as cx14 } from "@doowii/design-system/css";
import { Center } from "@doowii/design-system/jsx";
import { flex as flex3 } from "@doowii/design-system/patterns";
import {
  faChevronsLeft,
  faChevronsRight
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon12 } from "@fortawesome/react-fontawesome";
import { useId as useId3, useState as useState4 } from "react";
import { NavLink } from "react-router-dom";
import { jsx as jsx30, jsxs as jsxs19 } from "react/jsx-runtime";
var navBarStyles = cva10({
  base: {
    position: "relative",
    height: "100vh",
    p: "ld",
    zIndex: 2,
    bg: "base.white",
    borderRight: "1px solid token(colors.base.lightGray)",
    transition: "width 0.3s ease"
  },
  variants: {
    collapsed: {
      true: { width: "112px" },
      false: { width: "270px" }
    }
  },
  defaultVariants: {
    collapsed: false
  }
});
var navBarRoot = (props) => cx14(
  flex3({
    gap: "xl",
    direction: "column",
    align: "center"
  }),
  navBarStyles(props)
);
var navBarMenu = cx14(
  flex3({
    gap: "sm",
    direction: "column",
    align: "center",
    grow: 1
  }),
  css20({
    w: "100%",
    bg: "white"
  })
);
var navBarIcon = css20({
  w: "32px",
  h: "32px",
  minW: "32px"
});
var navBarItem = cx14(
  flex3({
    gap: "2.5",
    direction: "row",
    align: "center"
  }),
  css20({
    bg: "base.white",
    py: "3",
    px: "4",
    w: "100%",
    minWidth: "xl5",
    rounded: "xl",
    color: "base.darkBlue",
    fontSize: "20px",
    fontWeight: "medium",
    cursor: "pointer",
    _hover: {
      bg: "base.blueBrandSecondary"
    },
    _pressed: {
      bg: "base.blueBrandTertiary"
    }
  })
);
var disabledNavBarItem = css20({
  color: "base.lighterGrey",
  cursor: "not-allowed",
  pointerEvents: "none",
  opacity: 0.6
});
var collapseButton = css20({
  bg: "base.white",
  rounded: "full",
  cursor: "pointer",
  position: "absolute",
  top: "28px",
  right: "-20px",
  padding: "7px",
  color: "base.darkBlue",
  border: "1px solid token(colors.base.lightGray)",
  zIndex: 2,
  _hover: {
    bg: "base.blueBrandSecondary"
  },
  _pressed: {
    bg: "base.blueBrandTertiary"
  }
});
var NavBarItem = ({
  children,
  to,
  disabled = false
}) => /* @__PURE__ */ jsx30("li", { className: css20({ w: "100%" }), children: /* @__PURE__ */ jsxs19(
  NavLink,
  {
    "aria-disabled": disabled,
    className: ({ isActive }) => cx14(
      navBarItem,
      isActive && css20({ bg: "base.blueBrandTertiary" }),
      disabled && disabledNavBarItem
    ),
    tabIndex: disabled ? -1 : void 0,
    to,
    children: [
      /* @__PURE__ */ jsx30(Center, { className: navBarIcon, children: children.icon }),
      children.label
    ]
  }
) });
var NavBar = ({ navItems, footerButton }) => {
  const id = useId3();
  const [isCollapsed, setIsCollapsed] = useState4(false);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  return /* @__PURE__ */ jsxs19("div", { className: navBarRoot({ collapsed: isCollapsed }), children: [
    isCollapsed ? /* @__PURE__ */ jsx30(LogoD, {}) : /* @__PURE__ */ jsx30(LogoDoowii, {}),
    /* @__PURE__ */ jsx30(
      "button",
      {
        "aria-controls": `collapse-nav-${id}`,
        "aria-expanded": !isCollapsed,
        "aria-label": "Toggle navigation",
        className: collapseButton,
        id: `collapse-button-${id}`,
        onClick: toggleCollapse,
        type: "button",
        children: /* @__PURE__ */ jsx30(
          FontAwesomeIcon12,
          {
            className: css20({
              w: "24px",
              h: "24px",
              cursor: "pointer"
            }),
            icon: isCollapsed ? faChevronsRight : faChevronsLeft
          }
        )
      }
    ),
    /* @__PURE__ */ jsx30(
      "ul",
      {
        "aria-labelledby": `collapse-button-${id}`,
        className: navBarMenu,
        id: `collapse-nav-${id}`,
        children: navItems.map((item) => /* @__PURE__ */ jsx30(NavBarItem, { disabled: item.disabled, to: item.path, children: {
          icon: item.icon,
          label: isCollapsed ? null : item.label
        } }, item.path))
      }
    ),
    isCollapsed ? null : footerButton
  ] });
};

// src/PageLayout/PageLayout.tsx
import { Flex as Flex4 } from "@doowii/design-system/jsx";
import { useId as useId4 } from "react";
import { jsx as jsx31, jsxs as jsxs20 } from "react/jsx-runtime";
var PageLayout = ({
  title,
  loading,
  isEmpty,
  emptyProps,
  action,
  children
}) => {
  const id = useId4();
  return /* @__PURE__ */ jsxs20(
    Flex4,
    {
      css: {
        flexDirection: "column",
        width: "full",
        height: "full",
        px: "xl3",
        pb: "xl3"
      },
      children: [
        /* @__PURE__ */ jsxs20(
          Flex4,
          {
            css: {
              justifyContent: "space-between",
              alignItems: "center",
              mb: "ld"
            },
            children: [
              /* @__PURE__ */ jsx31(Heading, { level: 1, children: title }),
              !loading && !isEmpty ? action : null
            ]
          }
        ),
        /* @__PURE__ */ jsxs20(Flex4, { css: { w: "full", flex: 1 }, children: [
          loading ? /* @__PURE__ */ jsx31(
            Flex4,
            {
              css: { alignItems: "center", justifyContent: "center", w: "full" },
              children: /* @__PURE__ */ jsx31(LoadingLogo, {})
            }
          ) : null,
          isEmpty && emptyProps && !loading ? /* @__PURE__ */ jsxs20(
            Flex4,
            {
              css: {
                alignItems: "center",
                flexDirection: "column",
                gap: "8",
                w: "full"
              },
              children: [
                /* @__PURE__ */ jsx31(CircularIcon, { icon: emptyProps.icon }),
                /* @__PURE__ */ jsxs20(
                  Flex4,
                  {
                    css: {
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "2"
                    },
                    children: [
                      /* @__PURE__ */ jsx31(Heading, { level: 2, children: emptyProps.title }),
                      /* @__PURE__ */ jsx31(Text, { level: 4, children: emptyProps.description })
                    ]
                  }
                ),
                /* @__PURE__ */ jsx31(Button, { id: `${id}-empty-button`, onClick: emptyProps.onClick, children: emptyProps.buttonText })
              ]
            }
          ) : null,
          !loading && !isEmpty ? children : null
        ] })
      ]
    }
  );
};

// src/Progress/Progress.tsx
import { css as css21, cx as cx15 } from "@doowii/design-system/css";
import * as ProgressPrimitive from "@radix-ui/react-progress";
import * as React19 from "react";
import { jsx as jsx32 } from "react/jsx-runtime";
var Progress = React19.forwardRef(({ className, value, ...props }, ref) => /* @__PURE__ */ jsx32(
  ProgressPrimitive.Root,
  {
    className: cx15(
      css21({
        pos: "relative",
        h: "2",
        w: "full",
        overflow: "hidden",
        rounded: "full",
        bg: "base.lightGray"
      }),
      className
    ),
    ref,
    ...props,
    children: /* @__PURE__ */ jsx32(
      ProgressPrimitive.Indicator,
      {
        className: css21({
          h: "full",
          w: "full",
          flex: "1",
          bg: "linear-gradient(92.59deg, #F6CD3C 2.17%, #29BDF0 94.8%)"
        }),
        style: { transform: `translateX(-${100 - (value ?? 0)}%)` }
      }
    )
  }
));
Progress.displayName = ProgressPrimitive.Root.displayName;

// src/RequestBar/RequestBar.tsx
import { css as css22, cx as cx16 } from "@doowii/design-system/css";
import { flex as flex4 } from "@doowii/design-system/patterns";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon13 } from "@fortawesome/react-fontawesome";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import * as React20 from "react";
import { jsx as jsx33, jsxs as jsxs21 } from "react/jsx-runtime";
var RequestBar = AccordionPrimitive.Root;
var RequestBarItem = React20.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx33(
  AccordionPrimitive.Item,
  {
    className: cx16(
      css22({
        p: "4",
        w: "100%",
        bg: "base.blueBrandSecondary",
        rounded: "xl",
        border: "1px solid token(colors.base.lightGray)"
      }),
      className
    ),
    ref,
    ...props
  }
));
RequestBarItem.displayName = "RequestBarItem";
var RequestBarTrigger = React20.forwardRef(({ className, children, hasContent = false, ...props }, ref) => /* @__PURE__ */ jsx33(AccordionPrimitive.Header, { className: "flex", children: /* @__PURE__ */ jsxs21(
  AccordionPrimitive.Trigger,
  {
    className: cx16(
      flex4({
        direction: "row",
        align: "center",
        justify: "center",
        gap: "4"
      }),
      css22({
        w: "100% ",
        cursor: hasContent ? "pointer" : "text",
        userSelect: "text",
        '&[data-state="open"]': {
          "& .follow-up-text::before": {
            content: hasContent ? '"Hide follow-ups"' : '""'
          },
          "& .icon": {
            transform: hasContent ? "rotate(180deg)" : "none"
          }
        },
        '&[data-state="closed"]': {
          "& .follow-up-text::before": {
            content: hasContent ? '"Show follow-ups"' : '""'
          }
        }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx33(
        "div",
        {
          className: cx16(
            flex4({
              direction: "row",
              align: "center",
              justify: "flex-start",
              gap: "2",
              grow: 1
            }),
            css22({
              textStyle: "paragraph_18"
            })
          ),
          children
        }
      ),
      hasContent ? /* @__PURE__ */ jsxs21(
        "div",
        {
          className: cx16(
            flex4({
              direction: "row",
              align: "center",
              justify: "center",
              gap: "4"
            }),
            css22({
              p: "4",
              pr: "3",
              h: "10",
              textStyle: "label_16"
            })
          ),
          children: [
            /* @__PURE__ */ jsx33("span", { className: "follow-up-text" }),
            /* @__PURE__ */ jsx33(
              FontAwesomeIcon13,
              {
                className: cx16(
                  css22({
                    w: "6",
                    h: "6",
                    transition: "transform 0.2s"
                  }),
                  "icon"
                ),
                icon: faChevronDown
              }
            )
          ]
        }
      ) : null
    ]
  }
) }));
RequestBarTrigger.displayName = "RequestBarTrigger";
var RequestBarContent = React20.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs21(AccordionPrimitive.Content, { className: css22({}), ref, ...props, children: [
  /* @__PURE__ */ jsx33(
    "div",
    {
      className: css22({
        w: "100%",
        h: "0.5",
        my: "4",
        bg: "linear-gradient(97.52deg, #29BDF0 47.66%, #F6CD3C 65.7%)"
      })
    }
  ),
  /* @__PURE__ */ jsx33(
    "div",
    {
      className: cx16(
        flex4({
          direction: "column",
          align: "center",
          justify: "flex-start",
          gap: "2"
        }),
        css22({ pb: "4", pt: "0" }),
        className
      ),
      children
    }
  )
] }));
RequestBarContent.displayName = AccordionPrimitive.Content.displayName;

// src/SearchInput/SearchInput.tsx
import { css as css23 } from "@doowii/design-system/css";
import { Box as Box7, VisuallyHidden as VisuallyHidden3 } from "@doowii/design-system/jsx";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon14 } from "@fortawesome/react-fontawesome";
import { useEffect as useEffect2, useId as useId5, useRef as useRef2, useState as useState5 } from "react";
import { jsx as jsx34, jsxs as jsxs22 } from "react/jsx-runtime";
var SearchInput = ({
  variant = "standard",
  ...props
}) => {
  const id = useId5();
  const [isExpanded, setIsExpanded] = useState5(variant === "standard");
  const [isShrinking, setIsShrinking] = useState5(false);
  const inputRef = useRef2(null);
  useEffect2(() => {
    if (isExpanded && inputRef.current && variant !== "standard") {
      inputRef.current.focus();
    }
  }, [isExpanded, variant]);
  const toggleExpand = () => {
    if (variant === "expandable") {
      setIsExpanded((prev) => !prev);
    }
  };
  const handleBlur = () => {
    if (variant === "expandable" && inputRef.current?.value.trim() === "") {
      setIsShrinking(true);
      setTimeout(() => {
        setIsShrinking(false);
        setIsExpanded(false);
      }, 300);
    }
  };
  if (variant === "expandable" && !isExpanded && !isShrinking) {
    return /* @__PURE__ */ jsx34(
      Button,
      {
        "aria-expanded": isExpanded,
        "aria-label": "Expand search input",
        iconOnly: faSearch,
        onClick: toggleExpand,
        size: "medium",
        variant: "dropdown"
      }
    );
  }
  return /* @__PURE__ */ jsxs22(
    Box7,
    {
      css: {
        position: "relative",
        background: "white",
        borderRadius: "56px",
        animation: variant === "standard" ? "" : isShrinking ? `shrinkWidth 0.3s ease-in-out forwards` : `growWidth 0.3s ease-in-out forwards`
      },
      children: [
        /* @__PURE__ */ jsx34(
          Box7,
          {
            css: {
              position: "absolute",
              left: "md",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "15px"
            },
            children: /* @__PURE__ */ jsx34(FontAwesomeIcon14, { icon: faSearch })
          }
        ),
        /* @__PURE__ */ jsx34(VisuallyHidden3, { children: /* @__PURE__ */ jsx34("label", { htmlFor: id, children: props["aria-label"] }) }),
        /* @__PURE__ */ jsx34(
          "input",
          {
            className: css23({
              display: "flex",
              padding: "10px",
              pl: "xl3",
              width: "100%",
              alignItems: "center",
              position: "relative",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: "border.base",
              borderRadius: "56px",
              outline: "none"
            }),
            id,
            ref: inputRef,
            ...props,
            onBlur: handleBlur
          }
        )
      ]
    }
  );
};

// src/Select/Select.tsx
import { css as css24, cx as cx17 } from "@doowii/design-system/css";
import {
  faCheck as faCheck3,
  faChevronDown as faChevronDown2,
  faChevronUp
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon15 } from "@fortawesome/react-fontawesome";
import * as SelectPrimitive from "@radix-ui/react-select";
import * as React21 from "react";
import { jsx as jsx35, jsxs as jsxs23 } from "react/jsx-runtime";
var Select = SelectPrimitive.Root;
var SelectGroup = SelectPrimitive.Group;
var SelectValue = ({ name, ...rest }) => /* @__PURE__ */ jsx35(SelectPrimitive.Value, { ...rest });
var SelectTrigger = React21.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs23(
  SelectPrimitive.Trigger,
  {
    className: cx17(
      css24({
        display: "flex",
        w: "full",
        alignItems: "center",
        justifyContent: "space-between",
        color: "foreground.base",
        whiteSpace: "nowrap",
        rounded: "md",
        borderWidth: "2px",
        borderColor: "base.gray",
        bgColor: "transparent",
        p: "4",
        mt: "2",
        fontWeight: "500",
        lineHeight: "sm",
        cursor: "pointer",
        _focus: {
          ring: "none",
          ringOffset: "none",
          shadow: "1",
          borderColor: "base.blueBrandPrimary"
        },
        _disabled: { cursor: "not-allowed", opacity: "0.5" },
        "&>span": {
          overflow: "hidden",
          display: "-webkit-box",
          // @ts-ignore
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: "1"
        }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      children,
      /* @__PURE__ */ jsx35(SelectPrimitive.Icon, { asChild: true, children: /* @__PURE__ */ jsx35(
        FontAwesomeIcon15,
        {
          className: css24({ h: "4", w: "4", opacity: "0.5" }),
          icon: faChevronDown2
        }
      ) })
    ]
  }
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
var SelectScrollUpButton = React21.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx35(
  SelectPrimitive.ScrollUpButton,
  {
    className: cx17(
      css24({
        display: "flex",
        cursor: "default",
        alignItems: "center",
        justifyContent: "center",
        pt: "1",
        pb: "1"
      }),
      className
    ),
    ref,
    ...props,
    children: /* @__PURE__ */ jsx35(FontAwesomeIcon15, { icon: faChevronUp })
  }
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;
var SelectScrollDownButton = React21.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx35(
  SelectPrimitive.ScrollDownButton,
  {
    className: cx17(
      css24({
        display: "flex",
        cursor: "default",
        alignItems: "center",
        justifyContent: "center",
        pt: "1",
        pb: "1"
      }),
      className
    ),
    ref,
    ...props,
    children: /* @__PURE__ */ jsx35(FontAwesomeIcon15, { icon: faChevronDown2 })
  }
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;
var SelectContent = React21.forwardRef(({ className, children, position = "popper", ...props }, ref) => /* @__PURE__ */ jsx35(SelectPrimitive.Portal, { children: /* @__PURE__ */ jsxs23(
  SelectPrimitive.Content,
  {
    className: cx17(
      css24({
        pos: "relative",
        zIndex: "50",
        maxH: "96",
        minW: "8rem",
        overflow: "hidden",
        rounded: "md",
        shadow: "0px 4px 20px 0px #1539462E",
        bg: "white"
      }),
      position === "popper" && css24({
        // @ts-ignore
        "data-[side=bottom]": {
          transform: (
            // eslint-disable-next-line sonarjs/no-duplicate-string
            "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
          )
        },
        "data-[side=left]": {
          transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
        },
        "data-[side=right]": {
          transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
        },
        "data-[side=top]": {
          transform: "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
        }
      }),
      className
    ),
    position,
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx35(SelectScrollUpButton, {}),
      /* @__PURE__ */ jsx35(
        SelectPrimitive.Viewport,
        {
          className: cx17(
            css24({ p: "1" }),
            position === "popper" && css24({
              h: "var(.-radix-select-trigger-height)",
              w: "full",
              minW: "var(.-radix-select-trigger-width)"
            })
          ),
          children
        }
      ),
      /* @__PURE__ */ jsx35(SelectScrollDownButton, {})
    ]
  }
) }));
SelectContent.displayName = SelectPrimitive.Content.displayName;
var SelectLabel = React21.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx35(
  SelectPrimitive.Label,
  {
    className: cx17(
      css24({
        pl: "2",
        pr: "2",
        pt: "1.5",
        pb: "1.5",
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "semibold",
        color: "foreground.base"
      }),
      className
    ),
    ref,
    ...props
  }
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;
var SelectItem = React21.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsxs23(
  SelectPrimitive.Item,
  {
    className: cx17(
      css24({
        color: "foreground.base",
        pos: "relative",
        display: "flex",
        w: "full",
        cursor: "default",
        userSelect: "none",
        alignItems: "center",
        rounded: "sm",
        pt: "3",
        pb: "3",
        pl: "3",
        pr: "8",
        fontSize: "sm",
        lineHeight: "sm",
        ring: "none",
        ringOffset: "none",
        // @ts-ignore
        "data-[disabled]": { pointerEvents: "none", opacity: "0.5" },
        _hover: { bgColor: "base.blueBrandSecondary" },
        _active: { bgColor: "base.blueBrandTertiary" }
      }),
      className
    ),
    ref,
    ...props,
    children: [
      /* @__PURE__ */ jsx35(
        "span",
        {
          className: css24({
            pos: "absolute",
            right: "2",
            display: "flex",
            h: "3.5",
            w: "3.5",
            alignItems: "center",
            justifyContent: "center"
          }),
          children: /* @__PURE__ */ jsx35(SelectPrimitive.ItemIndicator, { children: /* @__PURE__ */ jsx35(FontAwesomeIcon15, { className: css24({ h: "4", w: "4" }), icon: faCheck3 }) })
        }
      ),
      /* @__PURE__ */ jsx35(SelectPrimitive.ItemText, { children })
    ]
  }
));
SelectItem.displayName = SelectPrimitive.Item.displayName;
var SelectSeparator = React21.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx35(
  SelectPrimitive.Separator,
  {
    className: cx17(
      css24({ ml: ".mx-1", mr: ".mx-1", mt: "1", mb: "1", h: "px" }),
      className
    ),
    ref,
    ...props
  }
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

// src/Separator/Separator.tsx
import { css as css25, cx as cx18 } from "@doowii/design-system/css";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import * as React22 from "react";
import { jsx as jsx36 } from "react/jsx-runtime";
var Separator3 = React22.forwardRef(
  ({ className, orientation = "horizontal", decorative = true, colorful = true, ...props }, ref) => /* @__PURE__ */ jsx36(
    SeparatorPrimitive.Root,
    {
      className: cx18(
        css25({ flexShrink: "0", bg: "base.lightGray" }),
        orientation === "horizontal" ? css25({ h: "1px", w: "full" }) : css25({ h: "full", w: "1px" }),
        colorful && css25({
          h: "0.5",
          // bgGradient: "gradient.primary",
          bg: "gradient.primary"
        }),
        className
      ),
      decorative,
      orientation,
      ref,
      ...props
    }
  )
);
Separator3.displayName = SeparatorPrimitive.Root.displayName;

// src/Skeleton/Skeleton.tsx
import { css as css26, cx as cx19 } from "@doowii/design-system/css";
import { Box as Box8 } from "@doowii/design-system/jsx";
import { jsx as jsx37 } from "react/jsx-runtime";
var Skeleton = ({ className, ...props }) => /* @__PURE__ */ jsx37(
  Box8,
  {
    className: cx19(
      css26({ animation: "pulse", bg: "base.lightherGray" }),
      className
    ),
    rounded: props.rounded ?? "md",
    ...props
  }
);

// src/SuggestionCard/SuggestionCard.tsx
import { css as css27, cx as cx20 } from "@doowii/design-system/css";
import { flex as flex5 } from "@doowii/design-system/patterns";
import { faPaperPlaneTop as faPaperPlaneTop3 } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon16 } from "@fortawesome/react-fontawesome";
import { jsx as jsx38, jsxs as jsxs24 } from "react/jsx-runtime";
var suggestionCardStyle = cx20(
  flex5({
    gap: "ld",
    direction: "row",
    align: "center",
    justify: "start"
  }),
  css27({
    bg: "base.white",
    p: "ld",
    rounded: "xl",
    cursor: "pointer",
    border: "1px solid token(colors.base.lightGray)",
    shadow: "regular",
    w: "full",
    maxWidth: "720px",
    minHeight: "92px",
    textAlign: "left",
    color: "base.darkBlue",
    _hover: {
      shadow: "hover",
      bg: "base.blueBrandSecondary"
    }
  })
);
var suggestionCardTextStyle = cx20(
  flex5({
    grow: 1
  }),
  css27({
    textStyle: "paragraph_16"
  })
);
var suggestionCardIconStyle = cx20(
  flex5({
    align: "center",
    justify: "center",
    shrink: 0
  }),
  css27({
    w: "32px",
    h: "32px"
  })
);
var SuggestionCard = ({
  onClick,
  children,
  label
}) => /* @__PURE__ */ jsxs24(
  "button",
  {
    "aria-label": label,
    className: suggestionCardStyle,
    onClick,
    type: "button",
    children: [
      /* @__PURE__ */ jsx38("div", { className: suggestionCardTextStyle, children }),
      /* @__PURE__ */ jsx38("div", { className: suggestionCardIconStyle, children: /* @__PURE__ */ jsx38(FontAwesomeIcon16, { icon: faPaperPlaneTop3 }) })
    ]
  }
);

// src/Switch/Switch.tsx
import * as React30 from "react";
import { css as css28, cx as cx21 } from "@doowii/design-system/css";

// ../../node_modules/@radix-ui/react-switch/dist/index.mjs
import * as React29 from "react";

// ../../node_modules/@radix-ui/primitive/dist/index.mjs
function composeEventHandlers(originalEventHandler, ourEventHandler, { checkForDefaultPrevented = true } = {}) {
  return function handleEvent(event) {
    originalEventHandler?.(event);
    if (checkForDefaultPrevented === false || !event.defaultPrevented) {
      return ourEventHandler?.(event);
    }
  };
}

// ../../node_modules/@radix-ui/react-context/dist/index.mjs
import * as React23 from "react";
import { jsx as jsx39 } from "react/jsx-runtime";
function createContextScope(scopeName, createContextScopeDeps = []) {
  let defaultContexts = [];
  function createContext3(rootComponentName, defaultContext) {
    const BaseContext = React23.createContext(defaultContext);
    const index = defaultContexts.length;
    defaultContexts = [...defaultContexts, defaultContext];
    function Provider3(props) {
      const { scope, children, ...context } = props;
      const Context = scope?.[scopeName][index] || BaseContext;
      const value = React23.useMemo(() => context, Object.values(context));
      return /* @__PURE__ */ jsx39(Context.Provider, { value, children });
    }
    function useContext2(consumerName, scope) {
      const Context = scope?.[scopeName][index] || BaseContext;
      const context = React23.useContext(Context);
      if (context) return context;
      if (defaultContext !== void 0) return defaultContext;
      throw new Error(`\`${consumerName}\` must be used within \`${rootComponentName}\``);
    }
    Provider3.displayName = rootComponentName + "Provider";
    return [Provider3, useContext2];
  }
  const createScope = () => {
    const scopeContexts = defaultContexts.map((defaultContext) => {
      return React23.createContext(defaultContext);
    });
    return function useScope(scope) {
      const contexts = scope?.[scopeName] || scopeContexts;
      return React23.useMemo(
        () => ({ [`__scope${scopeName}`]: { ...scope, [scopeName]: contexts } }),
        [scope, contexts]
      );
    };
  };
  createScope.scopeName = scopeName;
  return [createContext3, composeContextScopes(createScope, ...createContextScopeDeps)];
}
function composeContextScopes(...scopes) {
  const baseScope = scopes[0];
  if (scopes.length === 1) return baseScope;
  const createScope = () => {
    const scopeHooks = scopes.map((createScope2) => ({
      useScope: createScope2(),
      scopeName: createScope2.scopeName
    }));
    return function useComposedScopes(overrideScopes) {
      const nextScopes = scopeHooks.reduce((nextScopes2, { useScope, scopeName }) => {
        const scopeProps = useScope(overrideScopes);
        const currentScope = scopeProps[`__scope${scopeName}`];
        return { ...nextScopes2, ...currentScope };
      }, {});
      return React23.useMemo(() => ({ [`__scope${baseScope.scopeName}`]: nextScopes }), [nextScopes]);
    };
  };
  createScope.scopeName = baseScope.scopeName;
  return createScope;
}

// ../../node_modules/@radix-ui/react-use-controllable-state/dist/index.mjs
import * as React25 from "react";

// ../../node_modules/@radix-ui/react-use-callback-ref/dist/index.mjs
import * as React24 from "react";
function useCallbackRef(callback) {
  const callbackRef = React24.useRef(callback);
  React24.useEffect(() => {
    callbackRef.current = callback;
  });
  return React24.useMemo(() => (...args) => callbackRef.current?.(...args), []);
}

// ../../node_modules/@radix-ui/react-use-controllable-state/dist/index.mjs
function useControllableState({
  prop,
  defaultProp,
  onChange = () => {
  }
}) {
  const [uncontrolledProp, setUncontrolledProp] = useUncontrolledState({ defaultProp, onChange });
  const isControlled = prop !== void 0;
  const value = isControlled ? prop : uncontrolledProp;
  const handleChange = useCallbackRef(onChange);
  const setValue = React25.useCallback(
    (nextValue) => {
      if (isControlled) {
        const setter = nextValue;
        const value2 = typeof nextValue === "function" ? setter(prop) : nextValue;
        if (value2 !== prop) handleChange(value2);
      } else {
        setUncontrolledProp(nextValue);
      }
    },
    [isControlled, prop, setUncontrolledProp, handleChange]
  );
  return [value, setValue];
}
function useUncontrolledState({
  defaultProp,
  onChange
}) {
  const uncontrolledState = React25.useState(defaultProp);
  const [value] = uncontrolledState;
  const prevValueRef = React25.useRef(value);
  const handleChange = useCallbackRef(onChange);
  React25.useEffect(() => {
    if (prevValueRef.current !== value) {
      handleChange(value);
      prevValueRef.current = value;
    }
  }, [value, prevValueRef, handleChange]);
  return uncontrolledState;
}

// ../../node_modules/@radix-ui/react-use-previous/dist/index.mjs
import * as React26 from "react";
function usePrevious(value) {
  const ref = React26.useRef({ value, previous: value });
  return React26.useMemo(() => {
    if (ref.current.value !== value) {
      ref.current.previous = ref.current.value;
      ref.current.value = value;
    }
    return ref.current.previous;
  }, [value]);
}

// ../../node_modules/@radix-ui/react-use-size/dist/index.mjs
import * as React28 from "react";

// ../../node_modules/@radix-ui/react-use-layout-effect/dist/index.mjs
import * as React27 from "react";
var useLayoutEffect2 = Boolean(globalThis?.document) ? React27.useLayoutEffect : () => {
};

// ../../node_modules/@radix-ui/react-use-size/dist/index.mjs
function useSize(element) {
  const [size, setSize] = React28.useState(void 0);
  useLayoutEffect2(() => {
    if (element) {
      setSize({ width: element.offsetWidth, height: element.offsetHeight });
      const resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries)) {
          return;
        }
        if (!entries.length) {
          return;
        }
        const entry = entries[0];
        let width;
        let height;
        if ("borderBoxSize" in entry) {
          const borderSizeEntry = entry["borderBoxSize"];
          const borderSize = Array.isArray(borderSizeEntry) ? borderSizeEntry[0] : borderSizeEntry;
          width = borderSize["inlineSize"];
          height = borderSize["blockSize"];
        } else {
          width = element.offsetWidth;
          height = element.offsetHeight;
        }
        setSize({ width, height });
      });
      resizeObserver.observe(element, { box: "border-box" });
      return () => resizeObserver.unobserve(element);
    } else {
      setSize(void 0);
    }
  }, [element]);
  return size;
}

// ../../node_modules/@radix-ui/react-switch/dist/index.mjs
import { jsx as jsx40, jsxs as jsxs25 } from "react/jsx-runtime";
var SWITCH_NAME = "Switch";
var [createSwitchContext, createSwitchScope] = createContextScope(SWITCH_NAME);
var [SwitchProvider, useSwitchContext] = createSwitchContext(SWITCH_NAME);
var Switch = React29.forwardRef(
  (props, forwardedRef) => {
    const {
      __scopeSwitch,
      name,
      checked: checkedProp,
      defaultChecked,
      required,
      disabled,
      value = "on",
      onCheckedChange,
      ...switchProps
    } = props;
    const [button, setButton] = React29.useState(null);
    const composedRefs = useComposedRefs(forwardedRef, (node) => setButton(node));
    const hasConsumerStoppedPropagationRef = React29.useRef(false);
    const isFormControl = button ? Boolean(button.closest("form")) : true;
    const [checked = false, setChecked] = useControllableState({
      prop: checkedProp,
      defaultProp: defaultChecked,
      onChange: onCheckedChange
    });
    return /* @__PURE__ */ jsxs25(SwitchProvider, { scope: __scopeSwitch, checked, disabled, children: [
      /* @__PURE__ */ jsx40(
        Primitive.button,
        {
          type: "button",
          role: "switch",
          "aria-checked": checked,
          "aria-required": required,
          "data-state": getState(checked),
          "data-disabled": disabled ? "" : void 0,
          disabled,
          value,
          ...switchProps,
          ref: composedRefs,
          onClick: composeEventHandlers(props.onClick, (event) => {
            setChecked((prevChecked) => !prevChecked);
            if (isFormControl) {
              hasConsumerStoppedPropagationRef.current = event.isPropagationStopped();
              if (!hasConsumerStoppedPropagationRef.current) event.stopPropagation();
            }
          })
        }
      ),
      isFormControl && /* @__PURE__ */ jsx40(
        BubbleInput,
        {
          control: button,
          bubbles: !hasConsumerStoppedPropagationRef.current,
          name,
          value,
          checked,
          required,
          disabled,
          style: { transform: "translateX(-100%)" }
        }
      )
    ] });
  }
);
Switch.displayName = SWITCH_NAME;
var THUMB_NAME = "SwitchThumb";
var SwitchThumb = React29.forwardRef(
  (props, forwardedRef) => {
    const { __scopeSwitch, ...thumbProps } = props;
    const context = useSwitchContext(THUMB_NAME, __scopeSwitch);
    return /* @__PURE__ */ jsx40(
      Primitive.span,
      {
        "data-state": getState(context.checked),
        "data-disabled": context.disabled ? "" : void 0,
        ...thumbProps,
        ref: forwardedRef
      }
    );
  }
);
SwitchThumb.displayName = THUMB_NAME;
var BubbleInput = (props) => {
  const { control, checked, bubbles = true, ...inputProps } = props;
  const ref = React29.useRef(null);
  const prevChecked = usePrevious(checked);
  const controlSize = useSize(control);
  React29.useEffect(() => {
    const input = ref.current;
    const inputProto = window.HTMLInputElement.prototype;
    const descriptor = Object.getOwnPropertyDescriptor(inputProto, "checked");
    const setChecked = descriptor.set;
    if (prevChecked !== checked && setChecked) {
      const event = new Event("click", { bubbles });
      setChecked.call(input, checked);
      input.dispatchEvent(event);
    }
  }, [prevChecked, checked, bubbles]);
  return /* @__PURE__ */ jsx40(
    "input",
    {
      type: "checkbox",
      "aria-hidden": true,
      defaultChecked: checked,
      ...inputProps,
      tabIndex: -1,
      ref,
      style: {
        ...props.style,
        ...controlSize,
        position: "absolute",
        pointerEvents: "none",
        opacity: 0,
        margin: 0
      }
    }
  );
};
function getState(checked) {
  return checked ? "checked" : "unchecked";
}
var Root12 = Switch;
var Thumb = SwitchThumb;

// src/Switch/Switch.tsx
import { jsx as jsx41 } from "react/jsx-runtime";
var Switch2 = React30.forwardRef(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx41(
    Root12,
    {
      className: cx21(
        css28({
          all: "unset",
          width: "42px",
          // Default width
          height: "25px",
          // Default height
          backgroundColor: "#117DA3",
          borderRadius: "9999px",
          position: "relative",
          WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
          transition: "background-color 0.2s",
          '&[data-state="checked"]': { backgroundColor: "#33A665" },
          "&[data-disabled]": { backgroundColor: "#A9A9A9" }
        }),
        className
      ),
      ref,
      ...props,
      children: /* @__PURE__ */ jsx41(
        Thumb,
        {
          className: css28({
            display: "block",
            width: "21px",
            // Default thumb width
            height: "21px",
            // Default thumb height
            backgroundColor: "white",
            borderRadius: "9999px",
            boxShadow: "0 2px 2px rgba(0, 0, 0, 0.05)",
            transition: "transform 0.2s",
            transform: "translateX(2px)",
            // Default unchecked position
            '[data-state="checked"] &': { transform: "translateX(19px)" },
            // Checked position
            "[data-disabled] &": { backgroundColor: "#D3D3D3" }
          })
        }
      )
    }
  );
});
Switch2.displayName = "Switch";

// src/Table/SimpleTable.tsx
import { css as css31 } from "@doowii/design-system/css";
import { Box as Box10, Flex as Flex6 } from "@doowii/design-system/jsx";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table";

// src/Table/Table.tsx
import { css as css30 } from "@doowii/design-system/css";
import { Box as Box9, Flex as Flex5 } from "@doowii/design-system/jsx";
import {
  faChevronLeft,
  faChevronRight as faChevronRight3,
  faChevronsLeft as faChevronsLeft2,
  faChevronsRight as faChevronsRight2
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon as FontAwesomeIcon17 } from "@fortawesome/react-fontawesome";
import {
  flexRender
} from "@tanstack/react-table";
import { useCallback as useCallback3 } from "react";

// src/Table/TablePrimitive.tsx
import { css as css29, cx as cx22 } from "@doowii/design-system/css";
import * as React31 from "react";
import { jsx as jsx42 } from "react/jsx-runtime";
var Table = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx42("div", { className: css29({ pos: "relative", w: "full", overflow: "auto" }), children: /* @__PURE__ */ jsx42(
  "table",
  {
    className: cx22(
      css29({
        w: "full",
        captionSide: "bottom",
        fontSize: "sm",
        lineHeight: "sm"
      }),
      className
    ),
    ref,
    ...props
  }
) }));
Table.displayName = "Table";
var TableHeader = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx42("thead", { className: cx22(className), ref, ...props }));
TableHeader.displayName = "TableHeader";
var TableBody = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx42("tbody", { className: cx22(className), ref, ...props }));
TableBody.displayName = "TableBody";
var TableFooter = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx42(
  "tfoot",
  {
    className: cx22(
      css29({
        borderTopWidth: "1px",
        fontWeight: "medium",
        _last: { "&>tr": { borderBottomWidth: "0px" } }
      }),
      className
    ),
    ref,
    ...props
  }
));
TableFooter.displayName = "TableFooter";
var TableRow = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx42(
  "tr",
  {
    className: cx22(
      css29({
        borderBottomWidth: "1px",
        transitionProperty: "color, background-color, border-color, text-decoration-color, fill, stroke",
        transitionTimingFunction: "colors",
        transitionDuration: "colors"
      }),
      className
    ),
    ref,
    ...props
  }
));
TableRow.displayName = "TableRow";
var TableHead = React31.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsx42(
  "th",
  {
    className: cx22(
      css29({
        h: "12",
        px: "4",
        textAlign: "left",
        verticalAlign: "middle",
        fontWeight: "medium",
        "&:has([role=checkbox])": { pr: "0" }
      }),
      className
    ),
    ref,
    ...props,
    children
  }
));
TableHead.displayName = "TableHead";
var TableCell = React31.forwardRef(({ className, children, ...props }, ref) => /* @__PURE__ */ jsx42(
  "td",
  {
    className: cx22(
      css29({
        p: "4",
        verticalAlign: "middle",
        "&:has([role=checkbox])": { pr: "0" }
      }),
      className
    ),
    ref,
    ...props,
    children
  }
));
TableCell.displayName = "TableCell";
var TableCaption = React31.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx42(
  "caption",
  {
    className: cx22(
      css29({ mt: "4", fontSize: "sm", lineHeight: "sm" }),
      className
    ),
    ref,
    ...props
  }
));
TableCaption.displayName = "TableCaption";

// src/Table/Table.tsx
import { jsx as jsx43, jsxs as jsxs26 } from "react/jsx-runtime";
var TableHeader2 = ({
  table,
  className
}) => /* @__PURE__ */ jsx43(TableHeader, { className, children: table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ jsx43(TableRow, { children: headerGroup.headers.map((header) => /* @__PURE__ */ jsx43(TableHead, { children: header.isPlaceholder ? null : /* @__PURE__ */ jsx43(Label, { level: 1, children: flexRender(
  header.column.columnDef.header,
  header.getContext()
) }) }, header.id)) }, headerGroup.id)) });
var TableBody2 = ({
  table,
  className
}) => /* @__PURE__ */ jsx43(TableBody, { className, children: table.getRowModel().rows.map((row) => /* @__PURE__ */ jsx43(
  TableRow,
  {
    "data-state": row.getIsSelected() ? "selected" : null,
    children: row.getVisibleCells().map((cell) => /* @__PURE__ */ jsx43(TableCell, { children: cell.column.columnDef.isAction ? flexRender(cell.column.columnDef.cell, cell.getContext()) : /* @__PURE__ */ jsx43(Label, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }) }, cell.id))
  },
  row.id
)) });
var LoadingSkeleton = () => /* @__PURE__ */ jsxs26(Flex5, { css: { flexDirection: "column", gap: "md" }, children: [
  /* @__PURE__ */ jsxs26(Flex5, { css: { alignItems: "center", gap: "md" }, children: [
    /* @__PURE__ */ jsx43(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx43(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) }),
    /* @__PURE__ */ jsx43(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx43(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) }),
    /* @__PURE__ */ jsx43(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx43(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) }),
    /* @__PURE__ */ jsx43(Box9, { css: { flex: 1 }, children: /* @__PURE__ */ jsx43(Skeleton, { css: { height: "32px", flex: 1, maxW: "40%" } }) })
  ] }),
  Array.from(Array(11).keys()).map((index) => /* @__PURE__ */ jsxs26(
    Flex5,
    {
      css: {
        alignItems: "center",
        gap: "md",
        justifyContent: "space-between"
      },
      children: [
        /* @__PURE__ */ jsx43(Skeleton, { css: { height: "32px", flex: 1 } }),
        /* @__PURE__ */ jsx43(Skeleton, { css: { height: "32px", flex: 1 } }),
        /* @__PURE__ */ jsx43(Skeleton, { css: { height: "32px", flex: 1 } }),
        /* @__PURE__ */ jsx43(Skeleton, { css: { height: "32px", flex: 1 } })
      ]
    },
    index
  ))
] });
var DefaultEmptyComponent = () => /* @__PURE__ */ jsx43(Flex5, { css: { alignItems: "center", justifyContent: "center", h: "full" }, children: "No results." });
var Table2 = ({
  table,
  className,
  loading,
  emptyComponent
}) => {
  const renderTable = useCallback3(() => {
    if (loading) {
      return /* @__PURE__ */ jsx43(LoadingSkeleton, {});
    } else if (table.getRowModel().rows.length < 1) {
      return emptyComponent ?? /* @__PURE__ */ jsx43(DefaultEmptyComponent, {});
    } else {
      return /* @__PURE__ */ jsx43(Box9, { css: { rounded: "md", borderWidth: "1px" }, children: /* @__PURE__ */ jsxs26(Table, { children: [
        /* @__PURE__ */ jsx43(TableHeader2, { table }),
        /* @__PURE__ */ jsx43(TableBody2, { table })
      ] }) });
    }
  }, [table, loading, emptyComponent]);
  return /* @__PURE__ */ jsx43(Box9, { className, children: renderTable() });
};
var TablePaginationActions = ({
  table
}) => /* @__PURE__ */ jsxs26(
  Flex5,
  {
    css: {
      alignItems: "center",
      mr: "2",
      ml: "2",
      gap: "sm",
      color: "foreground.base"
    },
    children: [
      /* @__PURE__ */ jsxs26(
        Button,
        {
          className: css30({
            display: "none",
            h: "8",
            w: "8",
            p: "0",
            lg: { display: "flex" }
          }),
          disabled: !table.getCanPreviousPage(),
          onClick: () => table.setPageIndex(0),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx43(
              "span",
              {
                className: css30({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to first page"
              }
            ),
            /* @__PURE__ */ jsx43(
              FontAwesomeIcon17,
              {
                className: css30({ h: "4", w: "4" }),
                icon: faChevronsLeft2,
                size: "sm"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs26(
        Button,
        {
          className: css30({ h: "8", w: "8", p: "0" }),
          disabled: !table.getCanPreviousPage(),
          onClick: () => table.previousPage(),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx43(
              "span",
              {
                className: css30({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to previous page"
              }
            ),
            /* @__PURE__ */ jsx43(
              FontAwesomeIcon17,
              {
                className: css30({ h: "4", w: "4" }),
                icon: faChevronLeft,
                size: "xs"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs26(
        "div",
        {
          className: css30({
            display: "flex",
            w: "100px",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "sm",
            lineHeight: "sm",
            fontWeight: "medium"
          }),
          children: [
            "Page ",
            table.getState().pagination.pageIndex + 1,
            " of ",
            table.getPageCount()
          ]
        }
      ),
      /* @__PURE__ */ jsxs26(
        Button,
        {
          className: css30({ h: "8", w: "8", p: "0" }),
          disabled: !table.getCanNextPage(),
          onClick: () => table.nextPage(),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx43(
              "span",
              {
                className: css30({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to next page"
              }
            ),
            /* @__PURE__ */ jsx43(
              FontAwesomeIcon17,
              {
                className: css30({ h: "4", w: "4" }),
                icon: faChevronRight3,
                size: "xs"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs26(
        Button,
        {
          className: css30({
            display: "none",
            h: "8",
            w: "8",
            p: "0",
            lg: { display: "flex" }
          }),
          disabled: !table.getCanNextPage(),
          onClick: () => table.setPageIndex(table.getPageCount() - 1),
          variant: "dropdown",
          children: [
            /* @__PURE__ */ jsx43(
              "span",
              {
                className: css30({
                  pos: "absolute",
                  w: "sr.only",
                  h: "sr.only",
                  p: "sr.only",
                  m: "sr.only",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap",
                  borderWidth: "0"
                }),
                children: "Go to last page"
              }
            ),
            /* @__PURE__ */ jsx43(
              FontAwesomeIcon17,
              {
                className: css30({ h: "4", w: "4" }),
                icon: faChevronsRight2,
                size: "sm"
              }
            )
          ]
        }
      )
    ]
  }
);
var TablePaginationRPS = ({
  table
}) => /* @__PURE__ */ jsxs26(Flex5, { css: { alignItems: "center", mr: "2", ml: "2", gap: "sm" }, children: [
  /* @__PURE__ */ jsx43(
    "p",
    {
      className: css30({
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "medium",
        color: "foreground.base"
      }),
      children: "Rows per page"
    }
  ),
  /* @__PURE__ */ jsxs26(
    Select,
    {
      onValueChange: (value) => {
        table.setPageSize(Number(value));
      },
      value: `${table.getState().pagination.pageSize}`,
      children: [
        /* @__PURE__ */ jsx43(SelectTrigger, { className: css30({ h: "8", w: "70px" }), children: /* @__PURE__ */ jsx43(SelectValue, { placeholder: table.getState().pagination.pageSize }) }),
        /* @__PURE__ */ jsx43(SelectContent, { side: "top", children: [10, 20, 30, 40, 50].map((pageSize) => /* @__PURE__ */ jsx43(SelectItem, { value: `${pageSize}`, children: pageSize }, pageSize)) })
      ]
    }
  )
] });
var TablePagination = ({
  table
}) => /* @__PURE__ */ jsxs26(
  Flex5,
  {
    css: {
      alignItems: "center",
      justifyContent: "space-between",
      pl: "2",
      pr: "2"
    },
    children: [
      /* @__PURE__ */ jsx43(TablePaginationRPS, { table }),
      /* @__PURE__ */ jsx43(TablePaginationActions, { table })
    ]
  }
);

// src/Table/SimpleTable.tsx
import { jsx as jsx44, jsxs as jsxs27 } from "react/jsx-runtime";
var tableStyles = css31({
  "& > div": {
    bg: "base.white",
    color: "foreground.base",
    borderColor: "base.lightGray",
    px: "md",
    pt: "md",
    cursor: "text",
    "& thead": {
      bg: "base.lightherGray",
      "& th": {
        _firstOfType: {
          borderStartStartRadius: "md",
          borderEndStartRadius: "md"
        },
        _lastOfType: {
          borderStartEndRadius: "md",
          borderEndEndRadius: "md"
        }
      }
    },
    "& tr": {
      borderColor: "base.lightGray",
      cursor: "text",
      _lastOfType: {
        border: "none"
      },
      "& td": {
        py: "sm"
      }
    }
  }
});
var SimpleTable = ({
  columns,
  data,
  columnFilters,
  globalFilter,
  emptyComponent,
  setColumnFilters,
  setGlobalFilter
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      columnFilters,
      globalFilter
    }
  });
  return /* @__PURE__ */ jsxs27(Flex6, { css: { flexDirection: "column" }, children: [
    /* @__PURE__ */ jsx44(
      Table2,
      {
        className: tableStyles,
        emptyComponent,
        table
      }
    ),
    /* @__PURE__ */ jsx44(
      Flex6,
      {
        css: {
          alignItems: "center",
          justifyContent: "flex-end",
          mt: "md",
          gap: "md"
        },
        children: /* @__PURE__ */ jsx44(Box10, { children: /* @__PURE__ */ jsx44(TablePaginationActions, { table }) })
      }
    )
  ] });
};

// src/Textarea/Textarea.tsx
import { css as css32, cx as cx23 } from "@doowii/design-system/css";
import { Flex as Flex7 } from "@doowii/design-system/jsx";
import { jsx as jsx45, jsxs as jsxs28 } from "react/jsx-runtime";
var Textarea = ({ label, id, className, css: cssProp, ...props }) => /* @__PURE__ */ jsxs28(Flex7, { direction: "column", gap: "sm", children: [
  label && label.length > 0 ? /* @__PURE__ */ jsx45(Label, { htmlFor: id, level: 1, children: label }) : null,
  /* @__PURE__ */ jsx45(
    "textarea",
    {
      ...props,
      className: cx23(
        css32({
          p: "md",
          borderRadius: "md",
          borderWidth: "2px",
          borderColor: "border.base",
          outline: "none",
          resize: "none",
          color: "foreground.base"
        }),
        className,
        css32(cssProp)
      )
    }
  )
] });

// src/Toast/Toast.tsx
import {
  css as css33,
  cva as cva11,
  cx as cx24
} from "@doowii/design-system/css";
import { token } from "@doowii/design-system/tokens";
import {
  FontAwesomeIcon as FontAwesomeIcon18
} from "@fortawesome/react-fontawesome";
import * as ToastPrimitives from "@radix-ui/react-toast";
import * as React32 from "react";
import { jsx as jsx46 } from "react/jsx-runtime";
var ToastProvider = ToastPrimitives.Provider;
var ToastViewport = React32.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx46(ToastPrimitives.Viewport, { className, ref, ...props }));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;
var toastVariants = cva11({
  base: {
    pointerEvents: "auto",
    pos: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    m: "2",
    zIndex: "60",
    overflow: "hidden",
    rounded: "md",
    p: "md",
    pr: "ld",
    shadow: "lg",
    transitionProperty: "all",
    transitionTimingFunction: "all",
    transitionDuration: "all"
  },
  variants: {
    position: {
      "top-left": { top: 0, left: "0" },
      "top-right": { top: 0, right: "0" },
      "top-center": { top: 0, left: "50%", transform: "translateX(-50%)" },
      "bottom-left": { left: "0", bottom: "0" },
      "bottom-right": { right: "0", bottom: "0" },
      "bottom-center": {
        left: "50%",
        bottom: "0",
        transform: "translateX(-50%)"
      }
    },
    status: {
      info: {
        bgColor: "toast.info.background",
        color: "toast.info.foreground"
      },
      success: {
        bgColor: "toast.success.background",
        color: "toast.success.foreground"
      },
      warning: {
        bgColor: "toast.warning.background",
        color: "toast.warning.foreground"
      },
      error: {
        bgColor: "toast.error.background",
        color: "toast.error.foreground"
      }
    }
  },
  defaultVariants: { status: "info", position: "top-center" }
});
var Toast = React32.forwardRef(({ className, status, position, ...props }, ref) => /* @__PURE__ */ jsx46(
  ToastPrimitives.Root,
  {
    className: cx24(toastVariants({ status, position }), className),
    ref,
    ...props
  }
));
Toast.displayName = ToastPrimitives.Root.displayName;
var ToastAction = React32.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx46(
  ToastPrimitives.Action,
  {
    className: cx24(
      css33({
        display: "inline-flex",
        h: "8",
        flexShrink: "0",
        alignItems: "center",
        justifyContent: "center",
        rounded: "md",
        borderWidth: "1px",
        bgColor: "transparent",
        pl: "3",
        pr: "3",
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "medium",
        transitionProperty: "color, background-color, border-color, text-decoration-color, fill, stroke",
        transitionTimingFunction: "colors",
        transitionDuration: "colors",
        _focus: { ring: "none", ringOffset: "none", shadow: "1" },
        _disabled: { pointerEvents: "none", opacity: "0.5" }
      }),
      className
    ),
    ref,
    ...props
  }
));
ToastAction.displayName = ToastPrimitives.Action.displayName;
var ToastClose = React32.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx46(
  ToastPrimitives.Close,
  {
    className: cx24(
      css33({
        pos: "absolute",
        right: "1",
        top: "1",
        rounded: "md",
        p: "1",
        opacity: "0",
        transitionProperty: "opacity",
        transitionTimingFunction: "opacity",
        transitionDuration: "opacity",
        _focus: { opacity: "1", ring: "none", ringOffset: "none", shadow: "1" },
        _hover: { opacity: "1" }
      }),
      className
    ),
    ref,
    "toast-close": "",
    ...props,
    children: /* @__PURE__ */ jsx46(FontAwesomeIcon18, { icon: faClose })
  }
));
ToastClose.displayName = ToastPrimitives.Close.displayName;
var ToastTitle = React32.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx46(
  ToastPrimitives.Title,
  {
    className: cx24(
      css33({
        textStyle: "label_16",
        lineHeight: "sm",
        fontWeight: "400"
      }),
      className
    ),
    ref,
    ...props
  }
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;
var ToastDescription = React32.forwardRef(({ className, ...props }, ref) => /* @__PURE__ */ jsx46(
  ToastPrimitives.Description,
  {
    className: cx24(
      css33({ textStyle: "paragraph_14", opacity: "0.9" }),
      className
    ),
    ref,
    ...props
  }
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;
var statusIconMap = {
  info: faCircleInfo,
  success: faCircleCheck,
  warning: faCircleExclamation,
  error: faCircleExclamation
};
var iconColorMap = {
  info: token("colors.toast.info.icon"),
  success: token("colors.toast.success.icon"),
  warning: token("colors.toast.warning.icon"),
  error: token("colors.toast.error.icon")
};
var ToastIcon = ({
  status = "info",
  icon,
  loading
}) => {
  if (loading) {
    return /* @__PURE__ */ jsx46(Spinner, {});
  }
  return /* @__PURE__ */ jsx46(
    FontAwesomeIcon18,
    {
      color: iconColorMap[status],
      icon: icon ?? statusIconMap[status]
    }
  );
};

// src/Toast/Toaster.tsx
import { css as css34 } from "@doowii/design-system/css";
import { Box as Box11 } from "@doowii/design-system/jsx";

// src/Toast/useToast.tsx
import * as React33 from "react";
var TOAST_LIMIT = 1;
var TOAST_REMOVE_DELAY = 1e6;
var count = 0;
var genId = () => {
  count = (count + 1) % Number.MAX_SAFE_INTEGER;
  return count.toString();
};
var toastTimeouts = /* @__PURE__ */ new Map();
var addToRemoveQueue = (toastId) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }
  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      type: "REMOVE_TOAST",
      toastId
    });
  }, TOAST_REMOVE_DELAY);
  toastTimeouts.set(toastId, timeout);
};
var reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT)
      };
    case "UPDATE_TOAST":
      return {
        ...state,
        toasts: state.toasts.map(
          (t) => t.id === action.toast.id ? { ...t, ...action.toast } : t
        )
      };
    case "DISMISS_TOAST": {
      const { toastId } = action;
      if (toastId) {
        addToRemoveQueue(toastId);
      } else {
        state.toasts.forEach((toast2) => {
          addToRemoveQueue(toast2.id);
        });
      }
      return {
        ...state,
        toasts: state.toasts.map(
          (t) => t.id === toastId || toastId === void 0 ? {
            ...t,
            open: false
          } : t
        )
      };
    }
    case "REMOVE_TOAST":
      if (action.toastId === void 0) {
        return {
          ...state,
          toasts: []
        };
      }
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.toastId)
      };
  }
};
var memoryState = { toasts: [] };
var listeners = [];
var dispatch = (action) => {
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
};
var toast = ({ ...props }) => {
  const id = genId();
  const update = (props2) => dispatch({
    type: "UPDATE_TOAST",
    toast: { ...props2, id }
  });
  const dismiss = () => dispatch({ type: "DISMISS_TOAST", toastId: id });
  dispatch({
    type: "ADD_TOAST",
    toast: {
      ...props,
      id,
      open: true,
      onOpenChange: (open) => {
        if (!open) {
          dismiss();
        }
      }
    }
  });
  return {
    id,
    dismiss,
    update
  };
};
var useToast = () => {
  const [state, setState] = React33.useState(memoryState);
  React33.useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);
  return {
    ...state,
    toast,
    dismiss: (toastId) => dispatch({ type: "DISMISS_TOAST", toastId })
  };
};

// src/Toast/Toaster.tsx
import { jsx as jsx47, jsxs as jsxs29 } from "react/jsx-runtime";
var Toaster = () => {
  const { toasts } = useToast();
  return /* @__PURE__ */ jsxs29(ToastProvider, { children: [
    toasts.map(({ id, title, description, action, ...props }) => /* @__PURE__ */ jsxs29(Toast, { ...props, className: css34({ gap: "md" }), children: [
      /* @__PURE__ */ jsx47(ToastIcon, { icon: props.icon, status: props.status, loading: props.loading }),
      /* @__PURE__ */ jsxs29(Box11, { className: "grid gap-1", children: [
        title ? /* @__PURE__ */ jsx47(ToastTitle, { children: title }) : null,
        description ? /* @__PURE__ */ jsx47(ToastDescription, { children: description }) : null
      ] }),
      action,
      /* @__PURE__ */ jsx47(ToastClose, {})
    ] }, id)),
    /* @__PURE__ */ jsx47(ToastViewport, {})
  ] });
};

// src/Toggle/Toggle.tsx
import { css as css35, cx as cx25 } from "@doowii/design-system/css";
import { Box as Box12, Flex as Flex8 } from "@doowii/design-system/jsx";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import * as React34 from "react";
import { FontAwesomeIcon as FontAwesomeIcon19 } from "@fortawesome/react-fontawesome";
import { jsx as jsx48, jsxs as jsxs30 } from "react/jsx-runtime";
var Toggle = React34.forwardRef(
  ({ className, variant = "primary", size = "md", icon, label, secondaryLabel, ...props }, ref) => {
    const sizes = {
      xxs: { button: "h-5 px-1.5", icon: "w-1.5 h-1.5", text: "paragraph_12" },
      xs: { button: "h-6 px-2", icon: "w-2 h-2", text: "paragraph_12" },
      sm: { button: "h-8 px-3", icon: "w-3 h-3", text: "paragraph_14" },
      md: { button: "h-10 px-4", icon: "w-4 h-4", text: "paragraph_16" },
      lg: { button: "h-12 px-5", icon: "w-5 h-5", text: "paragraph_18" }
    };
    const variants = {
      primary: css35({
        bg: "background.base",
        color: "foreground.disabled",
        borderWidth: "1px",
        borderColor: "border.base",
        _hover: { bg: "background.hover.tertiary" },
        _focus: { ring: "2px", ringColor: "border.secondary" },
        _disabled: {
          bg: "background.disabled",
          borderColor: "border.disabled",
          cursor: "not-allowed"
        },
        _pressed: {
          bg: "background.primary",
          color: "foreground.primary",
          borderColor: "border.secondary",
          _hover: { bg: "base.darkBlue" }
        }
      }),
      secondary: css35({
        bg: "background.secondary",
        color: "foreground.disabled",
        _hover: { bg: "background.hover.secondary" },
        _focus: { ring: "2px", ringColor: "border.secondary" },
        _disabled: { opacity: 0.5, cursor: "not-allowed" },
        _pressed: {
          bg: "background.bar",
          color: "foreground.active",
          _hover: { bg: "base.blueBrandTertiary" }
        }
      }),
      expanded: css35({
        width: "100%",
        bg: "background.base",
        p: "md",
        rounded: "xl",
        shadow: "regular",
        _hover: { shadow: "hover" },
        _focus: { ring: "2px", ringColor: "border.secondary" },
        _disabled: { opacity: 0.5, cursor: "not-allowed" },
        _pressed: {
          bg: "background.bar",
          _hover: { bg: "base.blueBrandTertiary" }
        }
      })
    };
    const content = /* @__PURE__ */ jsxs30(Flex8, { align: "center", gap: "sm", children: [
      icon && /* @__PURE__ */ jsx48(FontAwesomeIcon19, { icon, className: sizes[size].icon }),
      label && /* @__PURE__ */ jsx48(Box12, { css: { textStyle: sizes[size].text }, children: label })
    ] });
    if (variant === "expanded" && secondaryLabel) {
      return /* @__PURE__ */ jsx48(TogglePrimitive.Root, { ref, className: cx25(variants[variant], className), ...props, children: /* @__PURE__ */ jsxs30(Flex8, { direction: "column", gap: "xs", children: [
        content,
        /* @__PURE__ */ jsx48(
          Box12,
          {
            css: {
              textStyle: "paragraph_14",
              color: "foreground.disabled"
            },
            children: secondaryLabel
          }
        )
      ] }) });
    }
    return /* @__PURE__ */ jsx48(
      TogglePrimitive.Root,
      {
        ref,
        className: cx25(
          css35({
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            rounded: "md",
            padding: "sm",
            cursor: "pointer",
            _focusVisible: {
              outline: "none"
            }
          }),
          variants[variant],
          sizes[size].button,
          className
        ),
        ...props,
        children: content
      }
    );
  }
);
Toggle.displayName = TogglePrimitive.Root.displayName;

// src/Accordion/Accordion.tsx
import { css as css36 } from "@doowii/design-system/css";
import { FontAwesomeIcon as FontAwesomeIcon20 } from "@fortawesome/react-fontawesome";
import { faChevronDown as faChevronDown3, faPlus } from "@fortawesome/pro-regular-svg-icons";
import * as AccordionPrimitive2 from "@radix-ui/react-accordion";
import { jsx as jsx49, jsxs as jsxs31 } from "react/jsx-runtime";
var AccordionRow = ({ title, content }) => {
  return /* @__PURE__ */ jsxs31(AccordionPrimitive2.Item, { value: title, className: css36({ mb: 3 }), children: [
    /* @__PURE__ */ jsx49(AccordionPrimitive2.Header, { children: /* @__PURE__ */ jsxs31(
      AccordionPrimitive2.Trigger,
      {
        className: css36({
          display: "flex",
          alignItems: "center",
          width: "100%",
          cursor: "pointer",
          p: 4,
          rounded: "md",
          bg: "white",
          _hover: { bg: "base.blueBrandSecondary", borderColor: "base.blueBrandSecondary" }
        }),
        children: [
          /* @__PURE__ */ jsx49(
            FontAwesomeIcon20,
            {
              icon: faPlus,
              className: css36({
                mr: 2
              })
            }
          ),
          /* @__PURE__ */ jsx49("span", { className: css36({ fontWeight: "medium" }), children: title })
        ]
      }
    ) }),
    /* @__PURE__ */ jsx49(
      AccordionPrimitive2.Content,
      {
        className: css36({
          bg: "base.blueBrandSecondary",
          color: "black",
          p: 4,
          mt: 2,
          rounded: "md"
        }),
        children: content
      }
    )
  ] });
};
var Accordion = ({ title, rows, onValueChange }) => {
  return /* @__PURE__ */ jsx49(
    AccordionPrimitive2.Root,
    {
      type: "single",
      collapsible: true,
      className: css36({
        w: "100%",
        maxW: "600px",
        mx: "auto",
        border: "1px solid",
        borderColor: "gray.200",
        rounded: "lg",
        overflow: "hidden"
      }),
      onValueChange,
      children: /* @__PURE__ */ jsxs31(AccordionPrimitive2.Item, { value: "main", children: [
        /* @__PURE__ */ jsxs31(AccordionPrimitive2.Header, { children: [
          /* @__PURE__ */ jsxs31(
            AccordionPrimitive2.Trigger,
            {
              className: css36({
                display: "flex",
                alignItems: "center",
                width: "100%",
                p: 4,
                cursor: "pointer",
                bg: "white",
                _hover: { bg: "base.blueBrandSecondary" }
              }),
              children: [
                /* @__PURE__ */ jsx49(LogoD, {}),
                /* @__PURE__ */ jsx49("span", { className: css36({ ml: 3, fontWeight: "semibold" }), children: title }),
                /* @__PURE__ */ jsx49("div", { className: css36({ flex: 1 }) }),
                /* @__PURE__ */ jsx49(
                  FontAwesomeIcon20,
                  {
                    icon: faChevronDown3,
                    className: css36({
                      transition: "transform 0.2s",
                      "[data-state=open] &": {
                        transform: "rotate(180deg)"
                      }
                    })
                  }
                )
              ]
            }
          ),
          " "
        ] }),
        /* @__PURE__ */ jsxs31(AccordionPrimitive2.Content, { children: [
          /* @__PURE__ */ jsx49(Separator3, {}),
          " ",
          /* @__PURE__ */ jsx49(
            "div",
            {
              className: css36({
                p: 4
              }),
              children: /* @__PURE__ */ jsx49(AccordionPrimitive2.Root, { type: "single", collapsible: true, children: rows.map((row, index) => /* @__PURE__ */ jsx49(AccordionRow, { ...row }, index)) })
            }
          )
        ] })
      ] })
    }
  );
};
export {
  Accordion,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Avatar,
  AvatarFallback,
  AvatarImage,
  BackgroundBox,
  Badge,
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  Callout,
  Card,
  Checkbox,
  CircularCounter,
  CircularIcon,
  ConfirmationDialog,
  Default,
  Dialog,
  DialogActionButtons,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  FeedbackButton,
  FollowUpCard,
  Heading,
  InputField,
  Label,
  LoadingLogo,
  Logo,
  LogoD,
  LogoDoowii,
  LogoLoader,
  MainSearchBar,
  NavBar,
  NavBarItem,
  PageLayout,
  Progress,
  RequestBar,
  RequestBarContent,
  RequestBarItem,
  RequestBarTrigger,
  SearchInput,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
  Separator3 as Separator,
  SimpleTable,
  Skeleton,
  Spinner,
  SuggestionCard,
  Switch2 as Switch,
  Table2 as Table,
  TableBody2 as TableBody,
  TableHeader2 as TableHeader,
  TablePagination,
  TablePaginationActions,
  TablePaginationRPS,
  Text,
  Textarea,
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastIcon,
  ToastProvider,
  ToastTitle,
  ToastViewport,
  Toaster,
  Toggle,
  Tooltip,
  WithIcon,
  buttonRecipe,
  cardRecipe,
  reducer,
  toast,
  useToast
};
