import {
  ChatDataBoard,
  ChatExplanation,
  LogoAvatar,
} from "@components/ChatContainer/ChatResultCard";
import { Feedback } from "@components/Feedback";
import { useChatData } from "@context/chat";
import { usePinboard } from "@context/pinboard";
import { Result } from "@doowii-types/chat";
import {
  faArrowDownLeftAndArrowUpRightToCenter,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faGripLinesVertical,
  faShare,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import * as Collapsible from "@radix-ui/react-collapsible";
import { css, cx } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";
import { token } from "@styled-system/tokens";
import { Button, Text } from "doowii-ui";
import React, { useMemo, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import { ChatFollowup } from "./ChatFollowup";
import { PinboardBadges } from "./PinboardBadges";

interface ChatResultCardProps {
  result: Result;
  index: number;
}

const ChatResultCard = ({ result, index }: ChatResultCardProps) => {
  const [open, setOpen] = React.useState(true);
  const [shareResultDialogIsOpen, setShareResultDialogIsOpen] = useState(false);
  const [apolloDialogIsOpen, setApolloDialogIsOpen] = useState(false);
  const { boards } = usePinboard();
  const { answer } = useChatData();
  const pinnedToBoardFlag = useMemo(
    () =>
      Array.from(boards.keys()).filter((key) => boards.get(key).pins.includes(result.id)).length >
      0,
    [boards, result.id]
  );

  return (
    <Collapsible.Root
      className={css({
        w: "full",
        marginX: "auto",
        bg: "base.white",
        rounded: "xl",
        border: "1px solid token(colors.base.lightGray)",
        shadow: "regular",
      })}
      onOpenChange={setOpen}
      open={open}
    >
      <div
        className={cx(
          flex({
            direction: "row",
            align: "center",
            justify: "space-between",
          }),
          css({
            px: "4",
            h: "16",
          })
        )}
      >
        <div
          className={flex({
            direction: "row",
            align: "center",
            gap: "4",
          })}
        >
          <LogoAvatar />
          <Collapsible.Trigger asChild>
            <button
              className={cx(
                flex({
                  direction: "row",
                  align: "center",
                  gap: "2",
                }),
                css({
                  cursor: "pointer",
                  color: "base.blueBrandPrimary",
                  p: "4",
                  pr: "3",
                })
              )}
              type="button"
            >
              <Text className={css({ fontWeight: "medium" })} level={3} variant="constrast">
                {open ? <Trans>Fold the results</Trans> : <Trans> Maximize the Results</Trans>}
              </Text>
              <FontAwesomeIcon
                className={css({
                  w: "24px",
                  h: "24px",
                })}
                icon={
                  open
                    ? faArrowDownLeftAndArrowUpRightToCenter
                    : faArrowUpRightAndArrowDownLeftFromCenter
                }
              />
            </button>
          </Collapsible.Trigger>
        </div>
        <Flex alignItems="center" gap="md">
          <Button
            iconRight={faShare}
            onClick={() => {
              setShareResultDialogIsOpen(true);
            }}
            size="small"
            variant="tertiary"
          >
            <Trans>Share Result</Trans>
          </Button>
          <Feedback feedbackResult={result.satisfied} index={index} />
        </Flex>
      </div>

      <Collapsible.Content>
        {pinnedToBoardFlag ? <PinboardBadges chatId={result.id} /> : null}
        <PanelGroup
          className={css({ borderTop: "1px solid token(colors.base.lightGray)" })}
          direction="horizontal"
        >
          <Panel className={css({ p: "4" })} minSize={10}>
            <ChatExplanation explanation={answer[index]} />
          </Panel>
          <PanelResizeHandle
            className={css({
              display: "flex",
              alignItems: "center",
              width: "7px",
              backgroundColor: "base.lightherGray",
              opacity: 0.75,
            })}
          >
            <FontAwesomeIcon color={token("colors.border.base")} icon={faGripLinesVertical} />
          </PanelResizeHandle>
          <Panel defaultSize={70} minSize={40}>
            <ChatDataBoard
              isApolloDialogOpen={apolloDialogIsOpen}
              isShareResultDialogOpen={shareResultDialogIsOpen}
              result={result}
              setIsApolloDialogOpen={setApolloDialogIsOpen}
              setIsShareResultDialogOpen={setShareResultDialogIsOpen}
            />
          </Panel>
        </PanelGroup>
        <ChatFollowup result={result} />
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export { ChatResultCard };
