import { UserAvatar } from "@components/UserAvatar";
import { Result } from "@doowii-types/chat";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { Text } from "doowii-ui";

interface ChatQuestionCardProps {
  result: Result;
}
const ChatQuestionCard = ({ result }: ChatQuestionCardProps) => (
  <Flex
    align="center"
    className={css({
      p: "4",
      w: "full",
      marginX: "auto",
      bg: "base.blueBrandSecondary",
      rounded: "xl",
      border: "1px solid token(colors.base.lightGray)",
    })}
    direction="row"
    gap="8"
    grow={1}
    justify="flex-start"
  >
    <UserAvatar />
    <Text level={4}>{result.query}</Text>
  </Flex>
);

export { ChatQuestionCard };
